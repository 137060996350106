<template>
  <v-row v-if="successAlertActive">
    <v-col cols="12" md="12">
      <v-alert
        text
        border="top"
        color="#05795C"
        class="mt-3"
      >
        <v-col cols="12">
          <span class="font-weight-bold" >{{ message }}</span>
        </v-col>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'SuccessAlert',
  props: { message: String, successAlertActive: Boolean },
};
</script>

<style>
.v-alert__border {
  opacity: 2 !important;
}
</style>
