<template>
    <v-row justify="center">
      <v-dialog :value="show" persistent max-width="590">
        <v-card>
          <v-card-title class="headline primary--text">
            <slot name="header"> {{ $t("common.dialogHeader") }} </slot>
          </v-card-title>
          <v-card-text>
            <slot name="content">
              {{ $t("common.dialogText") }}
            </slot>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="$emit('close')">
              {{ $t("common.cancel") }}
            </v-btn>
            <v-btn :loading="loadingConfirm" color="primary" @click="$emit('confirm')">
              {{ $t("common.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-row>
</template>

<script>
export default ({
  name: 'ExclusionDynamicModal',

  data: () => ({}),

  props: {
    show: Boolean,
    loadingConfirm: Boolean,
  },

});
</script>
