// SDIServiceManager.js
import BankService from './BankService';
import CollectorIntegrationService from './CollectorIntegrationService';
import CollectorService from './CollectorService';
import DataSourceService from './DataSourceService';
import DocumentService from './DocumentService';
import DocumentTypeService from './DocumentTypeService';
import EligibilityService from './EligibilityService';
import EmploymentRelationshipService from './EmploymentRelationshipService';
import ExtractService from './ExtractService';
import FavoriteFilterService from './FavoriteFilterService';
import FieldTypeService from './FieldTypeService';
import FileDataService from './FileDataService';
import FunctionService from './FunctionService';
import InsuranceCarrierBatchConfigService from './InsuranceCarrierBatchConfigService';
import InsuranceCarrierMovementActionService from './InsuranceCarrierMovementActionService';
import InsuranceCarrierPeriodicityService from './InsuranceCarrierPeriodicityService';
import InvoiceService from './InvoiceService';
import LayoutColumnService from './LayoutColumnService';
import LayoutService from './LayoutService';
import LayoutTagService from './LayoutTagService';
import ManagementImplantationService from './ManagementImplantationService';
import MovementErrorsService from './MovementErrorsService';
import MovementFileService from './MovementFileService';
import MovementInsuranceCarrierReturnService from './MovementInsuranceCarrierReturnService';
import MovementInsuranceCarrierService from './MovementInsuranceCarrierService';
import MovementRecordParametrizationService from './MovementRecordParametrizationService';
import MovementRecordService from './MovementRecordService';
import MovementRecordTypeService from './MovementRecordTypeService';
import MovementRulesService from './MovementRulesService';
import MovementRulesSpecifiedService from './MovementRulesSpecifiedService';
import OperatorService from './OperatorService';
import PayrollService from './PayrollService';
import PlanChangeReasonService from './PlanChangeReasonService';
import ReportService from './ReportService';
import RuleService from './RuleService';
import InternalNoteService from './InternalNoteService';
import CarrierCriticismService from './CarrierCriticismService';

class SDIServiceManager {
  constructor() {
    if (!SDIServiceManager.instance) {
      this.initServices();
      SDIServiceManager.instance = this;
    }

    return SDIServiceManager.instance;
  }

  initServices() {
    this.services = {
      bankService: new BankService(),
      collectorIntegrationService: new CollectorIntegrationService(),
      collectorService: new CollectorService(),
      dataSourceService: new DataSourceService(),
      documentService: new DocumentService(),
      documentTypeService: new DocumentTypeService(),
      eligibilityService: new EligibilityService(),
      employmentRelationshipService: new EmploymentRelationshipService(),
      extractService: new ExtractService(),
      favoriteFilterService: new FavoriteFilterService(),
      fieldTypeService: new FieldTypeService(),
      fileDataService: new FileDataService(),
      functionService: new FunctionService(),
      insuranceCarrierBatchConfigService: new InsuranceCarrierBatchConfigService(),
      insuranceCarrierMovementActionService: new InsuranceCarrierMovementActionService(),
      insuranceCarrierPeriodicityService: new InsuranceCarrierPeriodicityService(),
      invoiceService: new InvoiceService(),
      layoutColumnService: new LayoutColumnService(),
      layoutService: new LayoutService(),
      layoutTagService: new LayoutTagService(),
      managementImplantationService: new ManagementImplantationService(),
      movementErrorsService: new MovementErrorsService(),
      movementFileService: new MovementFileService(),
      movementInsuranceCarrierReturnService: new MovementInsuranceCarrierReturnService(),
      movementInsuranceCarrierService: new MovementInsuranceCarrierService(),
      movementRecordParametrizationService: new MovementRecordParametrizationService(),
      movementRecordService: new MovementRecordService(),
      movementRecordTypeService: new MovementRecordTypeService(),
      movementRulesService: new MovementRulesService(),
      movementRulesSpecifiedService: new MovementRulesSpecifiedService(),
      operatorService: new OperatorService(),
      payrollService: new PayrollService(),
      planChangeReasonService: new PlanChangeReasonService(),
      reportService: new ReportService(),
      ruleService: new RuleService(),
      internalNoteService: new InternalNoteService(),
      carrierCriticismService: new CarrierCriticismService(),
    };
  }

  getBankService() {
    return this.services.bankService;
  }

  getCollectorIntegrationService() {
    return this.services.collectorIntegrationService;
  }

  getCollectorService() {
    return this.services.collectorService;
  }

  getDataSourceService() {
    return this.services.dataSourceService;
  }

  getDocumentService() {
    return this.services.documentService;
  }

  getDocumentTypeService() {
    return this.services.documentTypeService;
  }

  getEligibilityService() {
    return this.services.eligibilityService;
  }

  getEmploymentRelationshipService() {
    return this.services.employmentRelationshipService;
  }

  getExtractService() {
    return this.services.extractService;
  }

  getFavoriteFilterService() {
    return this.services.favoriteFilterService;
  }

  getFieldTypeService() {
    return this.services.fieldTypeService;
  }

  getFileDataService() {
    return this.services.fileDataService;
  }

  getFunctionService() {
    return this.services.functionService;
  }

  getInsuranceCarrierBatchConfigService() {
    return this.services.insuranceCarrierBatchConfigService;
  }

  getInsuranceCarrierMovementActionService() {
    return this.services.insuranceCarrierMovementActionService;
  }

  getInsuranceCarrierPeriodicityService() {
    return this.services.insuranceCarrierPeriodicityService;
  }

  getInvoiceService() {
    return this.services.invoiceService;
  }

  getLayoutColumnService() {
    return this.services.layoutColumnService;
  }

  getLayoutService() {
    return this.services.layoutService;
  }

  getLayoutTagService() {
    return this.services.layoutTagService;
  }

  getManagementImplantationService() {
    return this.services.managementImplantationService;
  }

  getMovementErrorsService() {
    return this.services.movementErrorsService;
  }

  getMovementFileService() {
    return this.services.movementFileService;
  }

  getMovementInsuranceCarrierReturnService() {
    return this.services.movementInsuranceCarrierReturnService;
  }

  getMovementInsuranceCarrierService() {
    return this.services.movementInsuranceCarrierService;
  }

  getMovementRecordParametrizationService() {
    return this.services.movementRecordParametrizationService;
  }

  getMovementRecordService() {
    return this.services.movementRecordService;
  }

  getMovementRecordTypeService() {
    return this.services.movementRecordTypeService;
  }

  getMovementRulesService() {
    return this.services.movementRulesService;
  }

  getMovementRulesSpecifiedService() {
    return this.services.movementRulesSpecifiedService;
  }

  getOperatorService() {
    return this.services.operatorService;
  }

  getPayrollService() {
    return this.services.payrollService;
  }

  getPlanChangeReasonService() {
    return this.services.planChangeReasonService;
  }

  getReportService() {
    return this.services.reportService;
  }

  getRuleService() {
    return this.services.ruleService;
  }

  getInternalNoteService() {
    return this.services.internalNoteService;
  }

  getCarrierCriticismService() {
    return this.services.carrierCriticismService;
  }
}

const sdiServiceManager = new SDIServiceManager();

export default sdiServiceManager;
