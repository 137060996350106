/* eslint-disable */
import HttpService from '../HttpService'

export default class ExtractService {
  constructor() {
    this._httpService = new HttpService('/sdi/extract-revenue');
  }

  async Search(filters) {
    return await this._httpService.get(`${filters}`);
  }
  
  async GetExtract(extractId) {
    return await this._httpService.get(`/${extractId}`);
  }

  async CancelExtract(extractId, data) {
    return await this._httpService.post(`/${extractId}/cancellation`, data);
  }

  async DownloadExtract(extractId) {
    return await this._httpService.get(`/${extractId}/file`);
  }

  async ReprocessExtract(extractId, formData) {
    return await this._httpService.post(`/${extractId}/reprocessing`, formData);
  }
}
