/* eslint-disable */
import HttpService from '../HttpService';

export default class PlanChangeReasonService {
  constructor() {
    this._httpService = new HttpService('/sdi/plan-change-reason');
  }

  async FindAll() {
    return await this._httpService.get('/all');
  }
};
