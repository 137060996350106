<template>
  <v-dialog v-model="saveFilterModal" max-width="500" persistent>
    <v-card>
      <v-card-title style="justify-content: space-between;">
        <h2 class="primary--text">Salvar Pesquisa</h2>

        <div color="primary" class="clickable" style="cursor: pointer" @click="close()">
          <v-icon>mdi-close</v-icon>
        </div>
      </v-card-title>

      <v-card-text class="mt-3 pb-0">
        <label class="secondary--text text-subtitle-1">Insira um nome para a sua pesquisa</label>
        <v-form ref="formFavorite" @submit.prevent="newFavoriteFilter">
          <v-text-field
            outlined
            dense
            class="mt-2"
            v-model="favoriteTitle"
            required
            :rules="favoriteTitle ? [] : ['Preencha este campo']"
            validate-on-blur
            @blur="favoriteTitle ? favoriteTitle = formatter.formatToTitleCase(favoriteTitle) : null"
            :autofocus="isAutofocus"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="mx-2 pb-5" style="justify-content: space-between;">
        <v-btn outlined color="primary" @click="close()">
          <v-icon class="mr-2">fa-solid fa-angle-left</v-icon>
          <span>CANCELAR</span>
        </v-btn>
        <v-btn color="primary" type="submit" @click="newFavoriteFilter" :loading="loadingSaveButton">
          <v-icon left>fas fa-check</v-icon>
          <span>SALVAR</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';

export default ({
  name: 'SaveFilterModal',

  data: () => ({
    saveFilterModal: false,
    isAutofocus: false,
    favoriteTitle: null,
  }),

  props: {
    loadingSaveButton: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.formatter = new Formatters();
  },

  methods: {
    open() {
      this.saveFilterModal = true;
      this.$nextTick(() => {
        this.isAutofocus = true;
      });
    },
    close() {
      this.$refs.formFavorite.reset();
      this.saveFilterModal = false;
    },
    newFavoriteFilter() {
      if (this.$refs.formFavorite.validate()) {
        this.$emit('saveNewFavoriteFilter', this.favoriteTitle);
      }
    },
  },
});
</script>
