/* eslint-disable */
import HttpService from '../HttpService'

export default class CarrierCriticismService {
  constructor() {
    this._httpService = new HttpService('/sdi/carrier-criticism');
  }
  async Find() {
    return await this._httpService.get(`/find`);
  }

  async FindBy(params) {
    return await this._httpService.get(`/find?${params}`);
  }

  async Insert(data) {
    return await this._httpService.post('/insert', data);
  }

  async Update(params) {
    return await this._httpService.put('/update', params);
  }

  async Delete(ids) {
    return await this._httpService.delete(`/delete/${ids}`);
  }

  async UpdateClassification(params) {
    return await this._httpService.post('/classify', params);
  }

  async FindAll() {
    return await this._httpService.get('/classifications');
  }
}
