/* eslint-disable */
import { forEach } from 'lodash';
import HttpService from '../HttpService'

export default class DocumentService {
  constructor() {
    this._httpService = new HttpService('/sdi/document');
  }

  async UploadDocument(documentTypeId, file) {
    var formData = new FormData()
    formData.append('file', file)
    return await this._httpService.post(`/${documentTypeId}/upload`, formData);
  }

  async FindDocumentByFilters(params) {
    return await this._httpService.get(`/${params}`);
  }

  async CountDocumentByFilters(params) {
    return await this._httpService.get(`/count${params}`);
  }

  async DeleteSelectedDocuments(idDocuments) {
    return await this._httpService.delete(`/delete-documents${idDocuments}`);
  }

  async DownloadFileDocuments(idDocuments){
    return await this._httpService.get(`/download-documents${idDocuments}`, null, { responseType: 'blob' });
  }

  async UploadDocumentByFileDataId(fileDataId, files) {
    var formData = new FormData()
    files.forEach((file) => {
      formData.append('files', file)
    });

    return await this._httpService.post(`/upload-file-data-document/${fileDataId}`, formData);
  }

  findFiles(protocolNumber) {
    this._httpService.post(`/find-files/?protocolNumber=${protocolNumber}`);
  }

  findMovementFiles(protocolNumber) {
    this._httpService.post(`/find-movement-files/?protocolNumber=${protocolNumber}`);
  }

  findProtocolFiles(protocolNumber) {
    this._httpService.post(`/find-protocol-files/?protocolNumber=${protocolNumber}`);
  }

}
