/* eslint-disable */
import HttpService from '../HttpService'

export default class EmploymentRelationshipService {
  constructor() {
    this._httpService = new HttpService('/sdi/employment-relationship');
  }

  async FindAll() {
    return await this._httpService.get('');
  }
}
