<template style="background: #e5e5e5">
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="myReportsErrorModal"
        persistent
        max-width="1080">
        <v-card class="pa-5">
          <v-card-title class="headline pl-3 ml-3">
            <v-row>
              <v-col class="d-flex justify-start">
                <span
                  cols="12"
                  style="color: #3b495b; font-weight: bold">
                  Erro nos relatórios
                </span>
              </v-col>
              <v-col align="end">
                <v-icon large color="#4D596A" @click="closeModal()">
                  fas fa-times
                </v-icon>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="mt-10">
            <v-data-table
              :headers="headers"
              :items="errors"
              :items-per-page="10"
              :loading="isLoadingTable"
              class="elevation-1">
              <template v-slot:[`item.message`]="{ item }">
                <span>{{ item.message || "-" }}</span>
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                <span>
                  {{
                    item.createdAt
                      ? formatter.formatDateTimeZone(item.createdAt)
                      : "-"
                  }}
                </span>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <SnackbarCustomize ref="SnackbarCustomize" />
    </v-row>
  </div>
</template>

<script>
import Formatters from "@/shared/formatters/formatters"
import DynamicDialogInformation from "@/components/DynamicDialogInformation/DynamicDialogInformation.vue"
import SnackbarCustomize from "@/components/CustomAlerts/SnackbarCustomize.vue"

export default {
  name: "MyReportsErrorModal",
  components: { DynamicDialogInformation, SnackbarCustomize },
  data: () => ({
    myReportsErrorModal: false,
    isLoadingTable: false,
    detailError: "",
    errors: [],
    headers: [
    {
        text: "Descrição do Erro",
        align: "center",
        value: "message",
        sortable: false
      },
      {
        text: "Data",
        align: "center",
        value: "createdAt",
        sortable: false
      },
    ]
  }),

  props: {
    myReportsError: Array,
    insuredName: String,
    loadingTablemyReportsError: Boolean,
    myReportsStatus: String
  },

  watch: {
    myReportsError: {
      handler(val) {
        if (val && val.length > 0) {
          this.errors = val
        }
      },
      deep: true
    },
    loadingTableMyReportsError: {
      handler(val) {
        this.isLoadingTable = val
      },
      deep: true
    }
  },

  methods: {
    openModal() {
      this.myReportsErrorModal = true
    },
    closeModal() {
      this.errors = []
      this.$emit("cleanErrors")
      this.myReportsErrorModal = false
    }
  },

  created() {
    this.formatter = new Formatters()
  }
}
</script>
