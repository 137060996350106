<template>
  <v-dialog
    max-width="790"
    v-model="modal">
    <template v-slot:default="dialog">
      <v-card
        class="rounded-lg d-flex flex-column"
        style="min-height: 500px;">
        <v-card-title
          class="pt-9 px-12">
          <v-row
            class="text-center d-flex justify-start">
            <span
              class="text-h6 font-weight-bold primary--text">
              Cancelar Arquivo de Movimentação
            </span>
            <v-spacer />
            <v-icon
              size="25"
              color="grey"
              @click="close">
              close
            </v-icon>
          </v-row>
        </v-card-title>
        <v-card-text
          class="pa-9 d-flex flex-column justify-space-around flex-grow-1 flex-wrap">
          <div
            v-if="!showErrorMessage">
            <CancelationSucessInfo
              v-if="cancelationSuccess"
              :fileMovementToCancel="fileMovementToCancel"
            />
            <ConfirmCancelationInfo
              v-if="fileMovementToCancel.protocolNumber != null
                    && ['CONVERTED', 'COLLECTED'].some(status => status === fileMovementToCancel.status)
                    && fileMovementToCancel.processedElements === 0
                    && !cancelationSuccess"
              :fileMovementToCancel="fileMovementToCancel"
            />
            <ProcessFileWithFailsInfo
              v-if="['INVALID', 'REFUSED'].some(status => status === fileMovementToCancel.status)
                    && !cancelationSuccess"
              :fileMovementToCancel="fileMovementToCancel"
            />
            <ProcessFileInfo
              v-if="['PARTIAL', 'FINISHED'].some(status => status  === fileMovementToCancel.status)
                    && !cancelationSuccess"
              :fileMovementToCancel="fileMovementToCancel"
            />
          </div>
          <div
            v-else>
            <div
              class="d-flex justify-center"
              style="height: 120px;">
              <v-icon
                color="error"
                size="60">
                fas fa-ban
              </v-icon>
            </div>
            <v-sheet
              color="#F4F6F7"
              class="d-flex flex-column justify-center align-center font-weight-bold rounded-lg"
              style="width: 100%; height: 150px;">
              <p
                class="mb-0 pb-0 error--text">
                Os dados do arquivo nº {{ fileMovementToCancel.id }} não pode ser cancelado. :(
              </p>
            </v-sheet>
          </div>
        </v-card-text>
        <v-card-actions
          class="px-12 pb-9">
          <v-row
            no-gutters
            v-if="!showErrorMessage">
            <v-spacer />
            <v-btn
              class="text-body-2 elevation-0 primary--text mr-3"
              height="40"
              color="#EDEFF1"
              v-if="!['INVALID', 'REFUSED'].some(status => status === fileMovementToCancel.status)
                    && !cancelationSuccess"
              @click="close">
              <v-icon
                left>
                fas fa-arrow-left
              </v-icon>
              Voltar
            </v-btn>
            <v-btn
              height="40"
              class="text-body-2 elevation-0 primary"
              @click="requestCancellation"
              :loading="loading"
              v-if="fileMovementToCancel.protocolNumber != null
                    && ['CONVERTED', 'COLLECTED'].some(status => status === fileMovementToCancel.status)
                    && fileMovementToCancel.processedElements === 0
                    && !cancelationSuccess">
              Solicitar o cancelamento
              <v-icon
                right>
                fas fa-arrow-right
              </v-icon>
            </v-btn>
            <v-btn
              height="40"
              class="text-body-2 elevation-0 primary"
              @click="cancelMovements"
              :loading="loading"
              v-if="['PARTIAL', 'FINISHED'].some(status => status  === fileMovementToCancel.status)
                    && !cancelationSuccess">
              Cancelar movimentações
              <v-icon
                right>
                fas fa-arrow-right
              </v-icon>
            </v-btn>
            <v-btn
              height="40"
              class="text-body-2 elevation-0 primary"
              @click="close"
              :loading="loading"
              v-if="['INVALID', 'REFUSED'].some(status => status === fileMovementToCancel.status) || cancelationSuccess">
              Concluir
              <v-icon
                right>
                fas fa-arrow-right
              </v-icon>
            </v-btn>
          </v-row>
          <v-row
            v-else>
            <v-spacer />
            <v-btn
              height="40"
              class="text-body-2 elevation-0 primary"
              @click="close">
              Concluir
              <v-icon
                right>
                fas fa-arrow-right
              </v-icon>
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
      <SnackbarCustomize ref="SnackbarCustomize" />
    </template>
  </v-dialog>
</template>
<script>
import moment from 'moment';
import Rules from '@/shared/validators/formRules';
import AlertCard from "@/components/CustomAlerts/AlertCard.vue"
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import ConfirmCancelationInfo from './ConfirmCancelationInfo.vue';
import ProcessFileInfo from './ProcessFileInfo.vue';
import ProcessFileWithFailsInfo from './ProcessFileWithFailsInfo.vue';
import CancelationSucessInfo from './CancelationSucessInfo.vue';
import FileDataService from '@/services-http/sdi/FileDataService';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
export default {
  name: "CancelFileMovementModal",

  components: {
    AlertCard,
    ConfirmCancelationInfo,
    ProcessFileInfo,
    ProcessFileWithFailsInfo,
    CancelationSucessInfo,
    SnackbarCustomize,
  },

  data: () => ({
    rule: null,
    modal: false,
    moment: moment,
    movementService: null,
    fileDataService: null,
    cancelationSuccess: false,
    loading: false,

    fileMovementToCancel: null,
    showErrorMessage: false,
  }),

  methods: {
    open(item) {
      this.fileMovementToCancel = item;
      this.modal = true;
    },
    close() {
      this.modal = false;
      this.resetData();
      this.$emit('updatePage');
    },
    async requestCancellation() {
      this.loading = true;

      await this.fileDataService.CancelFileData(this.fileMovementToCancel.id).then((response) => {
        this.cancelationSuccess = true
      }).catch((err) => {
        if(err.response.data.codeMessage == 'file.data.cancel.not.allowed') {
          this.showErrorMessage = true;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    cancelMovements() {
      this.$emit('cancelMovements', this.fileMovementToCancel);
      this.close();
    },
    showResult() {
      this.$emit('showResult');
      this.close();
    },
    resetData() {
      this.cancelationSuccess = false;
      this.showErrorMessage = false;
    },
  },

  created() {
    this.rule = new Rules();
    this.movementService = new MovementRecordService();
    this.fileDataService = new FileDataService();
  },

  beforeDestroy() {
    this.resetData();
  }
}
</script>
