<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="auto" persistent>
      <v-card class="dialog-card pa-5">
        <v-card-title class="primary--text mb-1">
          {{ displayTitle }}
        </v-card-title>

        <v-card-subtitle class="font-weight-medium">
          <span>
            <b>{{ displayIdentifier }}</b> - {{ displaySecondaryInfo }}
          </span>
        </v-card-subtitle>

        <div v-show="view.infoSession">
          <v-card-text>
            <v-card class="grey lighten-4 rounded-lg" outlined>
              <v-row align="center">
                <v-col cols="auto" class="ml-5">
                  <v-icon color="green">fas fa-check</v-icon>
                </v-col>
                <v-col class="primary--text">
                  <v-card-title class="alert-title pl-0">
                    {{ alertInfoSessionTitle }}
                  </v-card-title>
                  <v-card-subtitle class="alert-subtitle pl-0">
                    {{ alertInfoSessionSubtitle }}
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
          <v-card-text>
            <v-card class="grey lighten-4 rounded-lg" outlined>
              <v-row align="center">
                <v-col cols="auto" class="ml-5">
                  <v-icon color="red">mdi-alert-outline</v-icon>
                </v-col>
                <v-col class="primary--text">
                  <v-card-title class="alert-title pl-0">
                    {{ alertSecondaryInfoSessionTitle }}
                  </v-card-title>
                  <v-card-subtitle class="alert-subtitle pl-0">
                    {{ alertSecondaryInfoSessionSubtitle }}
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
          <v-card-text>
            <v-row align="center">
              <v-col cols="auto" class="ml-5">
                <v-icon color="blue">mdi-information-slab-circle-outline</v-icon>
              </v-col>
              <v-col class="primary--text">
                <v-card-subtitle class="alert-subtitle pl-0">
                  <span>
                    <b>Importante: </b>
                    {{ `${isCancelMovements ? 'O cancelamento automático das movimentações é irreversível.' : 'A finalização automática das movimentações é irreversível.'}` }} <br>
                    Se você não tem certeza sobre esta ação, opte por atualizar as movimentações individualmente.
                  </span>
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-card-text>
        </div>

        <v-card-title v-show="view.overlaySession" class="primary--text my-15">
          <v-row>
            <v-col cols="12" align="center">
              <v-progress-circular
                color="primary"
                indeterminate
                size="64"
                width="8"
              />
            </v-col>
            <v-col cols=12 align="center">
              {{ `${isCancelMovements ? 'Cancelando movimentações' : 'Finalizando movimentações'}` }}
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-title v-show="view.finalSession" class="primary--text my-15">
          <v-row>
            <v-col cols="12" align="center">
              <v-icon color="green" size="45">
                fas fa-check
              </v-icon>
              <v-col class="pa-0 ma-0" cols=12 align="center">
                {{ performedMovementsText }}
              </v-col>
              <v-col class="pa-0 ma-0 text-subtitle-1" cols=12 align="center">
                {{ getTotalDescription }}
              </v-col>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-actions class="py-5 mb-3 mx-5">
          <v-row justify="space-between">
            <v-btn color="primary" large outlined @click="close()" :disabled="isOverlaySession">FECHAR</v-btn>
            <v-btn v-if="view.infoSession" color="primary" large @click="processMovements()">
              {{ isCancelMovements ? 'CONFIRMAR CANCELAMENTO' : 'CONFIRMAR FINALIZAÇÃO' }}
            </v-btn>
            <v-btn v-else-if="view.overlaySession" color="primary" large disabled>
              <v-icon class="mr-2">
                far fa-clock
              </v-icon>
              AGUARDE
            </v-btn>
            <v-btn v-else color="primary" large @click="filterMovementById()">
              <v-icon class="mr-2">
                fas fa-eye
              </v-icon>
              {{ isCancelMovements ? 'VER MOVIMENTAÇÕES CANCELADAS' : 'VER MOVIMENTAÇÕES FINALIZADAS' }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FileDataService from '@/services-http/sdi/FileDataService';
import MovementInsuranceCarrierService from '@/services-http/sdi/MovementInsuranceCarrierService';

export default ({
  name: 'CancelMovementsModal',
  data: () => ({
    dialog: false,
    awaitingRequest: false,
    view: {
      infoSession: true,
      overlaySession: false,
      finalSession: false,
    },
    performedMovements: 0,
    performedTotal: 0,
    inputData: {},
  }),

  computed: {
    displayTitle() {
      return this.isCancelMovements ? 'Cancelar movimentações' : 'Finalizar movimentações';
    },
    isOverlaySession() {
      return this.view.overlaySession;
    },
    isCancelMovements() {
      return this.inputData && this.inputData.isCancelMovements;
    },
    displayIdentifier() {
      if (this.inputData) {
        return this.isCancelMovements ? `Protocolo Nº ${this.inputData.protocolNumber || '-'}` : `Lote Nº ${this.inputData.id || '-'}`;
      }
      return '-';
    },
    displaySecondaryInfo() {
      return this.inputData ? (this.isCancelMovements ? this.inputData.financialGroup || '-' : this.inputData.carrier || '-') : '-';
    },
    alertInfoSessionTitle() {
      return this.isCancelMovements ? 'Serão canceladas' : 'Serão finalizadas';
    },
    alertInfoSessionSubtitle() {
      return this.isCancelMovements
        ? 'Movimentações válidas ou criticadas na corretora, com ou sem erros.'
        : 'Movimentações com status "Enviado para a operadora" e que não possuam número de cartão pendente de preenchimento.';
    },
    alertSecondaryInfoSessionTitle() {
      return this.isCancelMovements ? 'Não serão canceladas' : 'Não serão finalizadas';
    },
    alertSecondaryInfoSessionSubtitle() {
      return this.isCancelMovements
        ? 'Movimentações em processamento, enviadas para a operadora ou críticas na operadora, além das finalizadas ou em outras etapas avançadas..'
        : 'Movimentações em outros status ou enviadas para a operadora que necessitem da informação do cartão.';
    },
    performedMovementsText() {
      const actionText = this.isCancelMovements ? 'cancelada' : 'finalizada';
      const pluralText = this.performedMovements === 1 ? 'movimentação' : 'movimentações';
      return `${this.performedMovements} ${pluralText} ${actionText}`;
    },
    getTotalDescription() {
      const totalLabel = this.isCancelMovements ? 'Total do protocolo' : 'Total do lote';
      const nonEligibleMovements = this.performedTotal - this.performedMovements;

      return `${totalLabel} = ${this.performedTotal} registros / ${nonEligibleMovements} movimentações não elegíveis`;
    },
  },

  methods: {
    open(item) {
      this.inputData = item;
      this.dialog = true;
    },
    close() {
      this.view = {
        infoSession: true,
        overlaySession: false,
        finalSession: false,
      };
      this.dialog = false;
    },
    async processMovements() {
      if (this.inputData && this.inputData.id) {
        if (this.inputData.isCancelMovements) {
          await this.performMovementCancellation();
        }
        if (this.inputData.isEndMovemnts) {
          await this.performMovementFinalization();
        }
      }
    },
    async performMovementCancellation() {
      try {
        this.prepareOverlaySession();
        const response = await this.fileDataService.movementCancellation(this.inputData.id);
        if (response && response.data) {
          this.finalizeSession(response.data.canceled, response.data.total);
        }
      } catch (error) {
        this.close();
      }
    },
    async performMovementFinalization() {
      try {
        this.prepareOverlaySession();
        const response = await this.movementInsuranceCarrierService.MovementFinalization(this.inputData.id);
        if (response && response.data) {
          this.finalizeSession(response.data.finished, response.data.total);
        }
      } catch (error) {
        this.close();
      }
    },
    prepareOverlaySession() {
      this.view.infoSession = false;
      this.view.overlaySession = true;
    },
    finalizeSession(performedMovements, performedTotal) {
      this.view.overlaySession = false;
      this.view.finalSession = true;
      this.performedMovements = performedMovements;
      this.performedTotal = performedTotal;
    },
    filterMovementById() {
      this.close();
      const status = this.isCancelMovements ? 'CANCELED' : 'SUCCESS';
      this.$emit('filterMovementById', Number(this.inputData.id), status);
    },
  },

  created() {
    this.fileDataService = new FileDataService();
    this.movementInsuranceCarrierService = new MovementInsuranceCarrierService();
  },
});
</script>

<style scoped>
.dialog-card {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45%;
}

.dialog-transition-enter-active,
.dialog-transition-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.dialog-transition-enter,
.dialog-transition-leave-to {
  opacity: 0;
}

@media screen and (max-width: 1600px) and (min-width: 1199px) {
  .dialog-card {
    width: 60%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .dialog-card {
    width: 70%;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .dialog-card {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .dialog-card {
    width: 90%;
  }
}
</style>
