/* eslint-disable */
import HttpService from '../HttpService'

export default class InvoiceService {
  constructor() {
    this._httpService = new HttpService('/sdi/carrier-invoice');
  }

  async Search(filters) {
    return await this._httpService.get(`${filters}`);
  }

  async FindById(invoiceId) {
    return await this._httpService.get(`/${invoiceId}`);
  }
  
  async CancelInvoice(invoiceId, data) {
    return await this._httpService.post(`/${invoiceId}/cancellation`, data);
  }
  
  async DownloadInvoice(invoiceId) {
    return await this._httpService.get(`/${invoiceId}/file`);
  }
  
  async ReprocessInvoice(invoiceId, formData) {
    return await this._httpService.post(`/${invoiceId}/reprocessing`, formData);
  }
}
