/* eslint-disable */
import HttpService from '../HttpService'

export default class MovementErrorsService {
  constructor() {
    this._httpService = new HttpService('/sdi/movement-errors');
  }

  async FindAllByFilters(queryString) {
    return await this._httpService.get(`${queryString}`);
  }
}
