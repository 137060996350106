<template>
  <v-sheet
    :color="bodyColor"
    class="d-flex justify-center align-center pa-2 rounded-lg">
    <v-row
      no-gutters>
      <v-col
        cols="1"
        class="d-flex justify-center align-center">
        <v-icon
          size="18"
          :color="iconColor ? iconColor : textColor">
          {{ icon }}
        </v-icon>
      </v-col>
      <v-col
        cols="11">
        <v-row
          no-gutters>
          <v-col
            cols="12">
            <span
            :style="`color: ${textColor}`"
              class="text-caption font-weight-bold">
              {{ title }}
            </span>
          </v-col>
          <v-col
            cols="12">
            <span
              :style="`color: ${textColor}`"
              class="pa-0 text-caption">
              {{ description }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
export default {
  name: "AlertCard",

  props: {
    textColor: {
      type: String
    },
    bodyColor: {
      type: String
    },
    iconColor: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
    icon: {
      type: String,
      default: "fas fa-exclamation-circle"
    }
  }
}
</script>