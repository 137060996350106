/* eslint-disable */
import HttpService from '../HttpService'

export default class EligibilityService {
  constructor() {
    this._httpService = new HttpService('/sdi/eligibility');
  }

  async FindById(id) {
    return await this._httpService.get(`/${id}`);
  }

  async FindAllByContractId(params) {
    return await this._httpService.get('', params);
  }

  async Save(eligibilities) {
    return await this._httpService.post('', eligibilities);
  }

  async DeleteByIds(ids) {
    return await this._httpService.put('/delete', ids);
  }

  async Update(eligibilities) {
    return await this._httpService.put('', eligibilities);
  }

  async FindAll() {
    return await this._httpService.get('');
  }
}
