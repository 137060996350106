/* eslint-disable */
import HttpService from '../HttpService'

export default class MovementInsuranceCarrierReturnService {
  constructor() {
    this._httpService = new HttpService('/sdi/movement-insurance-carrier-return');
  }

  async FindByMovementRecordId(movementRecordId) {
    const parameters = new URLSearchParams();
    parameters.append('sort', 'id,desc');
    parameters.append('movementRecordId', movementRecordId);
    return await this._httpService.get('', parameters);
  }

  async FindCarrierInvalide(movementRecordId) {
    return await this._httpService.get(`/find-all-criticism-carrier/${movementRecordId}`);
  }
  
  async FindCriticismByParams(params) {
    return await this._httpService.get(`${params}`);
  }

  async Save(data, freeMovement) {
    return await this._httpService.post(`?isFreeMovement=${freeMovement}`, data);
  }

  async Update(data) {
    return await this._httpService.patch('/update-criticism', data);
  }

  async Delete(criticismId) {
    return await this._httpService.delete(`/${criticismId}`);
  }
}
