<template>
  <div class="text-center">
    <v-dialog v-model="showDialog" width="auto" persistent>
      <v-card class="dialog-card pa-5">
        <v-card-text>
          <v-row v-if="messageType == 'REVERT_TYPE_SUCCESS'" class="text-center py-5">
            <v-col cols="12">
              <v-icon color="green" x-large>fas fa-check</v-icon>
            </v-col>
            <v-col cols="12" class="primary--text">
              <h2><strong>Liberação revertida com sucesso</strong></h2>
            </v-col>
            <v-col cols="12" class="primary--text">
              <strong>Inclusão de Grupo Familiar</strong> - {{ beneficiaryName }}
            </v-col>
          </v-row>

          <v-row v-if="messageType == 'ACTION_BLOCK_TYPE_SUCCESS'" class="text-center py-5">
            <v-col cols="12">
              <v-icon color="green" x-large>fas fa-check</v-icon>
            </v-col>
            <v-col cols="12" class="primary--text">
              <h2><strong>{{ actionBlockSuccess && actionBlockSuccess.title ? actionBlockSuccess.title : '' }}</strong></h2>
            </v-col>
            <v-col cols="12" class="primary--text pt-0">
              <strong>{{ actionBlockSuccess && actionBlockSuccess.message ? actionBlockSuccess.message : '' }}</strong>
            </v-col>
          </v-row>

          <v-row v-if="messageType == 'REVERT_TYPE_ERROR'" class="text-center py-5">
            <v-col cols="12">
              <v-icon color="red" x-large>fas fa-times</v-icon>
            </v-col>
            <v-col cols="12" class="primary--text">
              <h2><strong>Ops... algo deu errado.</strong></h2>
            </v-col>
            <v-col cols="12" class="primary--text">
              <v-row align="center">
                <v-col class="primary--text">
                  <v-icon class="mr-2" color="blue">mdi-information-slab-circle-outline</v-icon>
                  Por favor tente novamente e, persistindo o erro acione o seu responsável de suporte.
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-if="messageType == 'ACTION_BLOCK_TYPE_ERROR'" class="text-center py-5">
            <v-col cols="12">
              <v-icon color="red" x-large>fas fa-times</v-icon>
            </v-col>
            <v-col cols="12" class="primary--text">
              <h2><strong>{{ actionBlockError && actionBlockError.title ? actionBlockError.title : 'Ops... algo deu errado' }}</strong></h2>
            </v-col>
            <v-col cols="12" class="primary--text">
              <v-row align="center" v-if="actionBlockError.align === 'center'">
                <v-col class="primary--text">
                  <v-icon class="mr-2" color="blue">mdi-information-slab-circle-outline</v-icon>
                  {{ actionBlockError && actionBlockError.message ? actionBlockError.message : 'Ocorreu um erro.' }}
                </v-col>
              </v-row>

              <v-row class="py-0 my-0" v-else>
                <v-col cols="auto" class="px-0">
                  <v-icon class="mr-2" color="blue">mdi-information-slab-circle-outline</v-icon>
                </v-col>
                <v-col class="primary--text text-justify" style="white-space: pre-wrap;">
                  {{ actionBlockError && actionBlockError.message ? actionBlockError.message : 'Ocorreu um erro.' }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-if="messageType == 'RELEASE_SUCCESS'" class="text-center py-5">
            <v-col cols="12">
              <v-icon color="green" x-large>fas fa-check</v-icon>
            </v-col>
            <v-col cols="12" class="primary--text">
              <h2><strong>Movimentação liberada com sucesso</strong></h2>
            </v-col>
            <v-col cols="12" class="primary--text">
              <strong>{{movementType}}</strong> - {{beneficiaryName}}
            </v-col>
          </v-row>

          <v-row v-if="messageType == 'RELEASE_ERROR'" class="text-center py-5">
            <v-col cols="12">
              <v-icon color="red" x-large>fas fa-times</v-icon>
            </v-col>
            <v-col cols="12" class="primary--text">
              <h2><strong>Ops... algo deu errado</strong></h2>
            </v-col>
            <v-col cols="12" class="primary--text">
              <strong>{{movementType}}</strong> - {{beneficiaryName}}
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="py-5 mb-3 mx-5">
          <v-row justify="center" v-if="messageType == 'REVERT_TYPE_SUCCESS' || messageType == 'REVERT_TYPE_ERROR' || messageType == 'ACTION_BLOCK_TYPE_ERROR'">
            <v-btn color="primary" large @click="close()">
              ENTENDI
              <v-icon class="ml-2" small>fas fa-chevron-right</v-icon>
            </v-btn>
          </v-row>
          <v-row  justify="center" v-else-if="messageType == 'ACTION_BLOCK_TYPE_SUCCESS'">
            <v-btn color="primary" outlined large @click="close()">
              <v-icon class="mr-2" small>fas fa-times</v-icon>
              FECHAR
            </v-btn>
            <v-btn color="primary" class="ml-5" large @click="showMovementsConfirmInfoModal()">
              <v-icon class="mr-2" small>fas fa-eye</v-icon>
              VER MOVIMENTAÇÕES ALTERADAS
            </v-btn>
          </v-row>
          <v-row  justify="center" v-else-if="messageType == 'RELEASE_SUCCESS'">
            <v-btn color="primary" class="ml-5" large @click="close()">
              <v-icon class="mr-2" small>fas fa-eye</v-icon>
              IR PARA LISTA DE MOVIMENTAÇÕES
            </v-btn>
          </v-row>
          <v-row  justify="center" v-else-if="messageType == 'RELEASE_ERROR'">
            <v-btn color="primary" outlined large @click="close()">
              <v-icon class="mr-2" small>fas fa-times</v-icon>
              ENTENDI
            </v-btn>
          </v-row>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default ({
  name: 'InfoMovementDialog',
  data: () => ({ showDialog: false }),
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    messageType: {
      type: String,
      default: 'REVERT_TYPE_SUCCESS',
    },
    beneficiaryName: {
      type: String,
      default: '',
    },
    actionBlockSuccess: {
      type: Object,
      default: () => ({
        message: '',
        title: '',
      }),
    },
    actionBlockError: {
      type: Object,
      default: () => ({
        message: 'Por favor, selecione pelo menos um registro.',
        title: 'Ops... nenhum registro selecionado',
        align: 'center',
      }),
    },
    movementType: {
      type: String,
      default: '',
    }
    ,
  },
  watch: {
    show(newValue) {
      this.showDialog = newValue;
    },
  },
  methods: {
    close() {
      this.showDialog = false;
      this.$emit('close');
    },
    showMovementsConfirmInfoModal() {
      this.$emit('showMovementsConfirmInfoModal');
    },
  },
});
</script>

<style scoped>
.dialog-card {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45%;
}

.dialog-transition-enter-active,
.dialog-transition-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.dialog-transition-enter,
.dialog-transition-leave-to {
  opacity: 0;
}

@media screen and (max-width: 1600px) and (min-width: 1199px) {
  .dialog-card {
    width: 60%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .dialog-card {
    width: 70%;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .dialog-card {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .dialog-card {
    width: 90%;
  }
}
</style>
