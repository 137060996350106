<template>
  <div>
    <v-dialog v-model="showModal" persistent width="auto">
      <v-card class="overflow-y-auto pa-5 dialog-card">
        <v-row class="mb-5">
          <v-col>
            <h1 class="secondary--text font-weight-light">Gerar lote de movimentações para a operadora</h1>
          </v-col>
        </v-row>
        <v-col class="pa-0" cols="12" md="3">
          <label class="label">Operadora</label>
          <v-autocomplete
            :items="insuranceCarriers"
            :loading="loadingCarriers"
            v-model="insuranceCarrierId"
            @change="loadLayoutListByInsuranceCarrierId()"
            item-text="xipp_commercial_name"
            item-value="id"
            class="mt-2"
            placeholder="Selecione a operadora"
            outlined
            color="textPrimary"
          />
        </v-col>

        <v-data-table
          class="elevation-1"
          sort-by="minLife"
          hide-default-footer
          item-key="id"
          :headers="headers"
          :items="batchs"
          :items-per-page="5"
          :calculate-widths="true"
          :loading="loadingBatchs"
        >
          <template v-slot:no-data>
            <span>Nenhum dado encontrado.</span>
          </template>
          <template v-slot:[`item.insuranceCarrierId`]="{ item }">
            <span>{{ item.insuranceCarrierId ? translateInsuranceCarrierBy(item.insuranceCarrierId) : '-' }}</span>
          </template>
          <template v-slot:[`item.benefitType`]="{ item }">
            <span>{{ item.benefitType ? formatBenefitType(item.benefitType) : '-' }}</span>
          </template>
          <template v-slot:[`item.movementTypes`]="{ item }">
            <span>{{ item.movementTypes ? formatMovementTypes(item.movementTypes) : '-' }}</span>
          </template>
          <template v-slot:[`item.layout`]="{ item }">
            <span>{{ (item.layout && item.layout.name) ? item.layout.name : '-' }}</span>
          </template>
          <template v-slot:[`item.generateBy`]="{ item }">
            <span>{{ item.generateBy ? translateGenerateBy(item.generateBy) : '-' }}</span>
          </template>
          <template v-slot:[`item.attachments`]="{ item }">
            <v-btn :loading="item.loading" small color="primary" dark @click="executeBatch(item)">
              Executar
            </v-btn>
          </template>
        </v-data-table>

        <div class="d-flex justify-end mt-2">
          <v-pagination
            v-model="page"
            color="textPrimary"
            :length="totalPages"
            :disabled="loadingBatchs"
            :total-visible="9"
            @input="loadLayoutListByInsuranceCarrierId()"
          />
        </div>

        <v-card-text class="ma-0 pa-0">
          <v-row align="center">
            <v-col cols="auto">
              <v-icon color="blue">mdi-information-slab-circle-outline</v-icon>
            </v-col>
            <v-col class="primary--text">
              <v-card-subtitle class="alert-subtitle pl-0">
                <span>Após a execução, aguarde alguns instantes e atualize a listagem de Lote Operadora<br>Se não houver o número de movimentações nas condições necessárias, o lote não será gerado.</br></span>
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="px-0">
          <v-col cols="12" align="end">
            <v-btn
              large
              outlined
              color="textPrimary"
              width="200px"
              @click="close()"
            >
              Fechar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackbarCustomize ref="SnackbarCustomize" />
  </div>
</template>

<script>
import Formatter from '@/shared/formatters/formatters';
import InsuranceCarrierBatchConfigService from '@/services-http/sdi/InsuranceCarrierBatchConfigService';
import BenefitService from '@/services-http/odoo/BenefitService';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';

export default ({
  name: 'BatchExecuteModal',

  components: { SnackbarCustomize },

  data: () => ({
    showModal: false,
    loadingBatchs: false,
    loadingCarriers: false,
    insuranceCarrierId: null,

    page: 1,
    totalPages: 0,

    benefitTypes: [],

    batchs: [],
    headers: [
      {
        text: 'Operadora',
        value: 'insuranceCarrierId',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Tipo de Benefício',
        value: 'benefitType',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Tipo de movimentação',
        value: 'movementTypes',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Nº Mín. vidas',
        value: 'minLife',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Layout',
        value: 'layout',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Granularidade',
        value: 'generateBy',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Ações',
        value: 'attachments',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
    ],
  }),

  props: { insuranceCarriers: Array },

  mounted() {
    this.loadBenetiType();
  },

  methods: {
    open() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
      this.insuranceCarrierId = null;
      this.batchs = [];
    },
    async loadBenetiType() {
      this.benefitService.FindAll().then((response) => {
        if (response && response.data && response.data.records.length) {
          this.benefitTypes = response.data.records.filter((benefit) => benefit.xipp_contract_record_type_code !== null);
        }
      });
    },
    async loadLayoutListByInsuranceCarrierId() {
      if (this.insuranceCarrierId) {
        this.loadingBatchs = true;
        this.insuranceCarrierBatchConfigService.FindAllByFilter({ insuranceCarrierId: this.insuranceCarrierId, page: this.page - 1, size: 10 }).then((response) => {
          if (response && response.data) {
            this.batchs = response.data.content.map((element) => ({
              ...element,
              loading: false,
            }));
            this.totalPages = response.data.totalPages;
            this.loadingBatchs = false;
          }
        }).catch(() => {
          this.loadingBatchs = false;
        });
      }
    },
    translateInsuranceCarrierBy(item) {
      let text = '';
      this.insuranceCarriers.forEach((element) => {
        if (element.id === item) {
          text = element.xipp_commercial_name;
        }
      });
      return text;
    },
    formatBenefitType(benefitType) {
      let benefitName = '-';
      if (this.benefitTypes && this.benefitTypes.length > 0) {
        const benefitFound = this.benefitTypes.find((benefit) => benefit.xipp_contract_record_type_code === benefitType);
        benefitName = benefitFound && benefitFound.name ? benefitFound.name : '-';
      }
      return benefitName;
    },
    formatMovementTypes(item) {
      const movementType = [];
      // eslint-disable-next-line no-param-reassign
      item.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.name = this.formatter.formatToTitleCase(element.name);
        movementType.push(` ${element.name}`);
      });
      return movementType.toString();
    },
    translateGenerateBy(item) {
      let text = '';
      if (item === 'FINANCIAL_GROUP') {
        text = 'Grupo Financeiro';
      }
      if (item === 'CONTRACT') {
        text = 'Contrato';
      }
      if (item === 'SUBCONTRACT') {
        text = 'Sub-Contrato';
      }
      if (item === 'INSURANCE_CARRIER') {
        text = 'Operadora';
      }
      return text;
    },
    async executeBatch(item) {
      if (!item || !item.id) {
        return;
      }

      if (item.flagExecutionManualNextCycle === 1 && item.flagInExecution === 0) {
        this.$refs.SnackbarCustomize.open('error', 'Este lote já está na fila para execução');
        return;
      }

      if (item.flagInExecution === 1) {
        this.$refs.SnackbarCustomize.open('error', 'Este lote já está em execução');
        return;
      }

      // eslint-disable-next-line no-param-reassign
      item.loading = true;
      try {
        await this.insuranceCarrierBatchConfigService.ExecuteBatch(item.id);
        this.$refs.SnackbarCustomize.open('blue', 'Lote será executado em instantes.');
        this.loadLayoutListByInsuranceCarrierId();
      } catch (error) {
        this.$refs.SnackbarCustomize.open('error', 'Erro ao executar o lote.');
      } finally {
        // eslint-disable-next-line no-param-reassign
        item.loading = false;
      }
    },
  },

  created() {
    this.formatter = new Formatter();
    this.insuranceCarrierBatchConfigService = new InsuranceCarrierBatchConfigService();
    this.benefitService = new BenefitService();
  },
});
</script>

<style scoped>
.dialog-card {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  top: 43%;
}

.dialog-transition-enter-active,
.dialog-transition-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.dialog-transition-enter,
.dialog-transition-leave-to {
  opacity: 0;
}

@media screen and (max-width: 1600px) and (min-width: 1199px) {
  .dialog-card {
    width: 80%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .dialog-card {
    width: 70%;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .dialog-card {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .dialog-card {
    width: 90%;
  }
}
</style>
