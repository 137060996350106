/* eslint-disable */
import HttpService from '../HttpService'

export default class OperatorService {
  constructor() {
    this._httpService = new HttpService('/sdi/operator');
  }

  async FindAll() {
    return await this._httpService.get();
  }

  async FindByContext(context) {
    return await this._httpService.get(`?context=${context}`);
  }
}
