/* eslint-disable */
import HttpService from '../HttpService';
export default class CarrierService {
    constructor() {
      this._httpService = new HttpService('/odoo/carrier');
    }

    async FindAllByCarrierId(carrierId) {
      return await this._httpService.get(`v2?fields=id,xipp_use_similar_card&id=${carrierId}`);
    }
 };
