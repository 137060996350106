<template>
  <v-card-text>
    <v-row align="center">
      <v-col class="pa-0 mt-5">
        <v-tabs v-model="tabValue" color="primary">
          <v-tab class="pr-6" key='criticism' href='#criticism' style="font-size: 18px">
            <v-icon class="pr-1">mdi-information-slab-circle-outline</v-icon>
              Críticas
            <v-badge
              v-if="countPendingCriticism && countPendingCriticism.length > 0"
              :content="countPendingCriticism.length"
              class="ml-1"
              color="red"
            />
          </v-tab>
          <v-tab
            v-if="conditionForViewTab()"
            key='internalNotes'
            href='#internalNotes'
            style="font-size: 18px"
            @click="$emit('changeTab')"
          >
            <v-icon class="pr-1">mdi mdi-message-reply-outline</v-icon>
              Notas Internas
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default ({
  name: 'TabsComponent',

  props: {
    value: { type: String, default: 'criticism' },
    isRHProtegido: { type: Boolean, default: false },
    authorities: { type: Object },
    countPendingCriticism: { type: Array },
    viewType: { type: String },
    criticismType: { type: String },
  },

  computed: {
    tabValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    conditionForViewTab() {
      if ((!this.isRHProtegido && this.authorities.hasAuthorityViewInternalNoteTab) && (this.viewType === 'fromMovementRecord' && this.criticismType === 'INVALID_IN_CARRIER')) {
        return true;
      }
      return false;
    },
  },
});
</script>
