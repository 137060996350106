/* eslint-disable */
import { AuthenticationService } from '@/services/authentication-service';
import HttpService from '../HttpService'
import axios from 'axios';
import userInfoModule from '@/store/modules/user-info/user-info-module';

export default class FileDataService {
  constructor() {
    this._httpService = new HttpService('/sdi/file-data');
    this._httpReportService = new HttpService('/sdi/report/movement-record/file');
  }

  async FindByParams(params) {
    return await this._httpService.get(`?${params}`);
  }

  async downloadFile(id){
    return await this._httpService.get(`/${id}/data`, null, { responseType: 'blob' });
  }

  async DownloadReport(id){
    return await this._httpReportService.get(`/${id}`, null, { responseType: 'blob' });
  }

  async downloadFileMovementCritical(id){
    return await this._httpService.get(`/movement-critical/${id}/data`, null, { responseType: 'blob' });
  }

  async movementCancellation(id){
    return await this._httpService.post(`/${id}/movement-cancellation`);
  }

  async CancelFileData(id){
    const API = `${process.env.VUE_APP_API}/sdi/file-data`;
    let appOrigin = null;
    let tokenActive = sessionStorage.getItem('@auth/token') || sessionStorage.getItem('@auth-odoo/token') || '';

    // if(sessionStorage.getItem('@sdi/app_origin')) appOrigin = sessionStorage.getItem('@sdi/app_origin');
    if(userInfoModule.data.appOrigin) appOrigin = userInfoModule.data.appOrigin;
    else appOrigin = 'BACKOFFICE';

    const config = {
      headers: {
        Authorization: tokenActive,
        'Access-Control-Max-Age': 600,
        'Cache-Control': 'max-age=600',
        'X-Sdi-App-Origin': appOrigin
      }
    };
    return await axios.post(`${API}/${id}/cancel`, null, config);
  }
}
