/* eslint-disable */
import HttpService from '../HttpService'

export default class LayoutService {
  constructor() {
    this._httpService = new HttpService('/sdi/layout/column');
  }

  async FindAllByFilter(query) {
    return await this._httpService.get(query);
  }
}
