<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        block
        color="textPrimary"
        outlined
        large
        dark
        height="40px"
        v-bind="attrs"
        v-on="on"
        @click="clearSelectedDate"
      >
        <v-icon small class="mr-2">fas fa-plus</v-icon> Criar Movimentação
      </v-btn>
    </template>
    <TodoListActions :actions="getActionCreateMovements" />
  </v-menu>
</template>

<script>
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import TodoListActions from '@/components/TodoList/TodoListActions.vue';
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin';

export default {
  name: 'CreateMovements',
  components: { TodoListActions },

  data: () => ({ isRHProtegido: false }),

  mixins: [
    VerifyRoutesMixin,
    AuthorityManagementMixin,
    GenericFunctionsMixin
  ],

  computed: {
    getActionCreateMovements() {
      const userPermissions = {
        viewImplantation: 'sdi_acao_implantacao',
        viewDependentInclusion: 'sdi_inclusao_dependente',
        viewFamilyGroup: 'sdi_inclusao_grupo_familiar',
        viewReactivation: 'sdi_reativacao_beneficiario',
        viewAlteration: 'sdi_alteracao_beneficiario',
        viewExclusion: 'sdi_exclusao_beneficiario',
      };

      const actions = [];

      actions.push({
        label: 'Implantação',
        icon: 'fas fa-plus',
        color: 'textPrimary',
        condition: this.hasPermission(userPermissions.viewImplantation),
        handler: () => this.redirectRouter('BeneficiaryPlanInformation', { isRHProtegido: this.isRHProtegido, movementType: 'implantation' }),
      });
      actions.push({
        label: 'Dependente Eventual',
        icon: 'fa-solid fa-user-plus',
        color: 'textPrimary',
        condition: this.hasPermission(userPermissions.viewDependentInclusion),
        handler: () => this.redirectRouter('DependentInclusion', { isRHProtegido: this.isRHProtegido }),
      });
      actions.push({
        label: 'Inclusão de Grupo Familiar',
        icon: 'fa-solid fa-users',
        color: 'textPrimary',
        condition: this.hasPermission(userPermissions.viewFamilyGroup),
        handler: () => this.redirectRouter('BeneficiaryPlanInformation', { isRHProtegido: this.isRHProtegido, movementType: 'insert' }),
      });
      actions.push({
        label: 'Reativação',
        icon: 'fa-solid fa-retweet',
        color: 'textPrimary',
        condition: this.hasPermission(userPermissions.viewReactivation),
        handler: () => this.redirectRouter('Reactivation', { isRHProtegido: this.isRHProtegido }),
      });
      actions.push({
        label: 'Alteração',
        icon: 'fa-solid fa-user',
        color: 'textPrimary',
        condition: this.hasPermission(userPermissions.viewAlteration),
        handler: () => this.redirectRouter('SearchHolderDependent', { isRHProtegido: this.isRHProtegido }),
      });
      actions.push({
        label: 'Exclusão',
        icon: 'fas fa-trash',
        color: 'textPrimary',
        condition: this.hasPermission(userPermissions.viewExclusion),
        handler: () => this.redirectRouter('ManualMovementDeleteSearch', { isRHProtegido: this.isRHProtegido }),
      });

      return actions;
    },
  },

  methods: {
    clearSelectedDate() {
      const selectedDate = 'selectedDate';
      const selectedDateValue = sessionStorage.getItem(selectedDate);
      this.clearSessionStorage();
      sessionStorage.setItem(selectedDate, selectedDateValue);
    },
  },
};
</script>
