/* eslint-disable */
import HttpService from '../HttpService'

export default class MovementRulesService {
  constructor() {
    this._httpService = new HttpService('/sdi/movement-specific-rules');
  }

  async FindAllByFilter(params) {
    const parameters = new URLSearchParams(params);
    parameters.append('sort', 'id,desc');
    return await this._httpService.get('', parameters);
  }
}
