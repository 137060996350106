<template>
  <v-dialog v-model="ModalWithActionSlot" persistent :max-width="widthModal">
    <v-card :max-height="heightCard" class="overflow-y-auto pa-5">
      <slot name="card" />
    </v-card>
  </v-dialog>
</template>

<script>

export default ({
  name: 'ModalWithActionSlot',

  data: () => ({ ModalWithActionSlot: false }),

  props: {
    widthModal: {
      type: Number,
      default: 700,
      require: false,
    },
    heightCard: {
      type: Number,
      default: 480,
      require: false,
    },
  },

  methods: {
    open() {
      this.ModalWithActionSlot = true;
    },
    close() {
      this.ModalWithActionSlot = false;
    },
  },
});
</script>
