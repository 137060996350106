/* eslint-disable */
import HttpService from '../HttpService'

export default class PayrollService {
  constructor() {
    this._httpService = new HttpService('/sdi/payroll');
  }

  async FindAllByFilters(params) {
    return await this._httpService.get(`${params}`);
  }

  async FindPayrollSource() {
    return await this._httpService.get('/payroll-source');
  }
}