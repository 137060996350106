<template>
  <div>
    <div
      class="d-flex justify-center"
      style="height: 120px;">
      <v-icon
        color="success"
        size="60">
        far fa-check-circle
      </v-icon>
    </div>
    <v-sheet
      color="#F4F6F7"
      class="d-flex flex-column justify-center align-center font-weight-bold rounded-lg"
      style="width: 100%; height: 150px;">
      <p
        class="mb-0 pb-0 success--text">
        Cancelamento do arquivo nº {{ fileMovementToCancel.id }} solicitado com sucesso. ;)
      </p>
      <p
        class="font-weight-regular pb-0 mb-0 grey--text">
        Nenhuma movimentação será processada. Você poderá acompanhar o status do arquivo na lista de uploads.
      </p>
    </v-sheet>
  </div>
</template>
<script>
export default {
  name: "CancelationSucessInfo",

  props: {
    fileMovementToCancel: null
  }
}
</script>