/* eslint-disable */
import HttpService from '../HttpService'

export default class BankService {
  constructor() {
    this._httpService = new HttpService('/sdi/bank');
  }

  async FindAll() {
    const url = new URLSearchParams();
    url.append('size', 1000);
    return await this._httpService.get('', url);
  }
}
