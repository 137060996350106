/* eslint-disable */
import HttpService from '../HttpService'

export default class InternalNoteService {
  constructor() {
    this._httpService = new HttpService('/sdi/internal-note');
  }

  async FindByMovementRecordId(id) {
    return await this._httpService.get(`?movementRecordId=${id}`);
  }
  
  async Save(data) {
    return await this._httpService.post('', data);
  }

  async Update(id, data) {
    return await this._httpService.patch(`/${id}`, data);
  }

  async Delete(id) {
    return await this._httpService.delete(`/${id}`);
  }
}
