<template style="background: #E5E5E5">
  <div>
    <v-row justify="center">
      <v-dialog v-model="movementErrorModal" persistent max-width="1080">
        <v-card class="pa-5">
          <v-card-title class="headline pl-3 ml-3">
            <v-row>
              <v-col align="start">
                <span cols="12" style="color: #3b495b; font-weight: bold">Erro de movimentação </span>
              </v-col>
              <v-col align="end">
                <v-icon large color="#4D596A" @click="closeModal()">fas fa-times</v-icon>
              </v-col>

              <v-col v-if="tabError === 'movement'" cols="12" style="color: #4d596a">
                Nome:
                <span style="font-weight: bold">{{ insuredName ? insuredName : '-' }}</span>
              </v-col>

              <template v-if="tabError === 'batch'">
                <v-col v-if="statusMovement === 'TRANSFORMATION_ERROR'" cols="12" style="color: #4d596a">
                  <span style="font-weight: bold">Esse lote tem movimentações com erros de transformação</span>
                </v-col>

                <v-col v-if="statusMovement === 'CONVERSION_ERROR' && (errors && errors.length > 0)" class="my-5 ml-3" cols="12" style="color: #4d596a">
                  <v-row>
                    {{ errors[0].message ? errors[0].message : '-' }}
                  </v-row>
                </v-col>
              </template>
            </v-row>
          </v-card-title>

          <v-card-text v-if="tabError !== 'batch'" :class="tabError === 'protocol' ? 'mt-10' : ''">
            <v-data-table
              :headers="headers"
              :items="errors"
              :items-per-page="10"
              :loading="isLoadingTable"
              class="elevation-1"
            >
              <template v-slot:[`header.lineNumber`]="{ header }">
                <span>{{ tabError === 'protocol' ? header.text : null }}</span>
              </template>
              <template v-slot:[`item.message`]="{ item }">
                <span>{{ item.message ? item.message : '-' }}</span>
              </template>
              <template v-slot:[`item.lineNumber`]="{ item }">
                <span>{{ item.lineNumber ? item.lineNumber : tabError === 'protocol' && !item.lineNumber ? '-' : '' }}</span>
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                <span>{{ item.createdAt ? formatter.formatDateTimeZone(item.createdAt) : '-' }}</span>
              </template>
              <template v-slot:[`item.detail`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="item.detail === null" color="primary" small v-bind="attrs" v-on="on" @click="onClickDetail(item)">
                      Ver detalhes
                    </v-btn>
                  </template>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>

          <v-row
            v-if="hasErrorReportRow">
            <v-col cols="12" class="py-0">
              <v-row justify="end" class="pt-6 pb-3">
              <v-col align="left" cols="12" xs="12" sm="12" md="3" lg="3">
                <v-btn
                  color="#3B495B"
                  outlined
                  @click="closeModal()"
                  style="width: 200px; font-size: 16px;"
                >
                  {{ tabError === 'batch' ? 'Cancelar' : 'Voltar' }}
                </v-btn>
              </v-col>
                <v-col align="end" class="mr-5">
                  <v-btn class="mr-5" color="primary" outlined @click="downloadReport()">
                    <v-icon left dark>fas fa-print</v-icon>
                    Relatório de erros
                  </v-btn>
                </v-col>
                <v-col align="end" class="mr-5">
                  <v-btn class="mr-5" :disabled="(errors && errors.length > 0) && errors[0].layoutId === null" color="primary" outlined @click="downloadFile()">
                    <v-icon left dark>fas fa-download</v-icon>
                    Baixar arquivo para correção
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row justify="start" class="mt-10">
              <v-col v-if="tabError !== 'protocol' && tabError !== 'movement'" align="center" cols="12" xs="12" sm="12" md="3" lg="3">
                <v-btn
                  color="#3B495B"
                  dark
                  @click="onClickDetail()"
                  style="width: 200px; font-size: 16px;"
                >
                  {{ tabError === 'batch' ? 'Ver detalhes' : 'OK' }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <DynamicDialogInformation ref="DynamicDialogInformation">
        <v-row class="mb-3" justify="center" slot="textInfo" style="word-break: break-word">
          <v-col cols="12" align="center">
            <label class="label"> Informações técnicas:</label>
          </v-col>
          {{ detailError && detailError.detail ? detailError.detail : 'Não há detalhes' }}
        </v-row>
      </DynamicDialogInformation>
      <SnackbarCustomize
      ref="SnackbarCustomize"
    />
    </v-row>
  </div>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import DynamicDialogInformation from '@/components/DynamicDialogInformation/DynamicDialogInformation.vue';
import FileDataService from '@/services-http/sdi/FileDataService';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import ReportService from '@/services-http/sdi/ReportService';

export default {
  name: 'MovementErrorModal',
  components: { DynamicDialogInformation, SnackbarCustomize },
  data: () => ({
    movementErrorModal: false,
    isLoadingTable: false,
    detailError: '',
    errors: [],
    headers: [
      {
        text: 'Data',
        align: 'center',
        value: 'createdAt',
        sortable: false,
      },
      {
        text: 'Descrição do Erro',
        align: 'center',
        value: 'message',
        sortable: false,
      },
      {
        text: 'Coluna do Erro',
        align: 'center',
        value: 'columnError',
        sortable: false,
      },
      {
        text: 'Linha do Erro',
        align: 'center',
        value: 'lineNumber',
        sortable: false,
      },
      {
        text: 'Beneficiário',
        align: 'center',
        value: 'benefitDescription',
        sortable: false,
      },
      {
        text: 'Ações',
        align: 'center',
        value: 'detail',
        sortable: false,
      },
    ],
  }),

  props: {
    movementError: Array,
    insuredName: String,
    insuranceCarrierIdWithError: null,
    loadingTableMovementError: Boolean,
    tabError: String,
    statusMovement: String,
    hasErrorReportRow: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    movementError: {
      handler(val) {
        if (val && val.length > 0) {
          this.errors = val;
        }
      },
      deep: true,
    },
    loadingTableMovementError: {
      handler(val) {
        this.isLoadingTable = val;
      },
      deep: true,
    },
  },

  methods: {
    openModal() {
      this.errors = [];
      this.movementErrorModal = true;
    },
    closeModal() {
      this.movementErrorModal = false;
    },
    onClickDetail(item) {
      if (this.statusMovement === 'TRANSFORMATION_ERROR') {
        this.$emit('redirectMovements', this.insuranceCarrierIdWithError, null);
        this.closeModal();
      } else {
        this.$refs.DynamicDialogInformation.open();
        this.detailError = item;
      }
    },

    downloadFile() {
      const fileDataService = new FileDataService();

      const id = this.errors[0].fileDataId;

      this.isLoadingTable = true;

      fileDataService.downloadFileMovementCritical(id).then((response) => {
        if (response) {
          const filename = response.headers['content-disposition'].split('"');
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', filename[1]);
          document.body.appendChild(fileLink);

          fileLink.click();
          this.isLoadingTable = false;
          this.$refs.SnackbarCustomize.open('success', 'Download realizado com sucesso.', 3000);
        }
      }).catch(() => {
        this.isLoadingTable = false;
        this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar efetuar o download.');
      });
    },

    downloadReport() {
      const reportService = new ReportService();

      const id = this.errors[0].fileDataId;

      this.isLoadingTable = true;

      reportService.findReportByFileData(id).then((response) => {
        if (response) {
          const filename = response.headers['content-disposition'].split('"');
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', filename[1]);
          document.body.appendChild(fileLink);

          fileLink.click();
          this.isLoadingTable = false;
          this.$refs.SnackbarCustomize.open('success', 'Download realizado com sucesso.', 3000);
        }
      }).catch(() => {
        this.isLoadingTable = false;
        this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar efetuar o download.');
      });
    },

  },

  created() {
    this.formatter = new Formatters();
  },
};
</script>
