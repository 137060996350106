/* eslint-disable */
import HttpService from '../HttpService'

export default class CollectorIntegrationService {
  constructor() {
    this._httpService = new HttpService('/sdi/collector-integration-config');
  }

  async FindAllByFilters(params) {
    return await this._httpService.get(`${params}`,);
  }

  async DeleteConfigById(id) {
    return await this._httpService.delete(`/${id}`);
  }
}