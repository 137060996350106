/* eslint-disable */
import HttpService from '../HttpService'

export default class MovementRecordParametrizationService {
  constructor() {
    this._httpService = new HttpService('/sdi/movement-record-parametrization');
  }

  async FindById(id) {
    return await this._httpService.get(`/${id}`);
  }

  async FindAllByFilters(params) {
    return await this._httpService.get('/find_all_by_filters', params);
  }

  async Save(data) {
    return await this._httpService.post('/insert', data);
  }

  async Update(id, data) {
    return await this._httpService.put(`/${id}`, data);
  }

  async Delete(id) {
    return await this._httpService.delete(`/${id}`);
  }
}