/* eslint-disable */
import HttpService from '../HttpService'
export default class ReportService {
  constructor() {
    this._httpService = new HttpService('/sdi/report');
  }

  async findReportFileByParams(parameters){
    return await this._httpService.post(`/movement-record?${parameters}`, null, { responseType: 'blob' });
  }

  async findReportByFileData(fileDataId){
    return await this._httpService.get(`/movement-errors-file/${fileDataId}`, null, { responseType: 'blob' });
  }
}
