<template>
  <div
    class="d-flex flex-column justify-space-around flex-wrap">
    <div>
      <p
        class="pb-0 mb-0">
        Tem certeza de que deseja cancelar o processamento do arquivo de movimentação abaixo?
      </p>
      <p>
        Ao prosseguir, nenhuma movimentação cadastral será gerada.
      </p>
    </div>
    <v-sheet
      color="#F4F6F7"
      class="d-flex flex-column justify-center align-center font-weight-bold rounded-lg"
      style="width: 100%; height: 150px;">
      <p
        class="mb-0 pb-0"
        style='color: #41B1B1'>
        Protocolo {{ fileMovementToCancel.id }}
      </p>
      <p
        class="font-weight-regular mb-0 pb-0"
        style='color: #41B1B1'>
        {{ fileMovementToCancel.fileName }}
      </p>
    </v-sheet>
  </div>
</template>
<script>
export default {
  name: "ConfirmCancelationInfo",

  props: {
    fileMovementToCancel: null
  }
}
</script>