<template>
  <v-dialog
    v-model="modal"
    max-width="750">
    <template v-slot:default="dialog">
      <v-card
        class="rounded-lg">
        <v-toolbar
          width="100%"
          :color="color ? color : 'wine'"
          height="79"
          style="position: relative;">
          <v-row
            class="text-center d-flex justify-center">
            <span
              class="text-h5 white--text font-weight-bold">
              {{ title }}
            </span>
            <v-btn
              icon
              class="white elevation-4"
              :color="color ? color : 'wine'"
              heiht="43"
              max-height="43"
              width="43"
              style="position: absolute; right: 5%; top: 20%;"
              @click="close">
              <v-icon
                size="30">
                close
              </v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
        <v-card-text
          class="mt-1 pb-0"
          style="height: 100px;">
          <v-container
            fuild
            class="fill-height pb-0">
            <v-row>
              <v-col
                cols="12"
                class="text-center">
                <span
                  class="text-h6 wineLabel--text font-weight-regular">
                  {{ description }}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions
          class="px-13 pb-8 pt-7">
          <v-row>
            <v-spacer />
            <v-btn
              height="50"
              width="150"
              color="blue-grey lighten-5"
              class="rounded-pill mr-6"
              @click="close">
              <v-icon
              :color="color ? color : 'wine'">
                mdi mdi-arrow-left
              </v-icon>
              <span
                :class="color ? 'wineLabel--text  text-h6' : 'wine--text text-h6'">
                Voltar
              </span>
            </v-btn>
            <v-btn
              height="50"
              width="260"
              :color="color ? color : 'wine'"
              class="rounded-pill"
              @click="confirm">
              <span
                class="white--text text-h6">
                {{ confirmLabel }}
              </span>
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
export default {
  name: "ConfirmationModal",

  data: () => ({
    modal: false,
    dataId: null,
  }),

  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    confirmLabel: {
      type: String,
      default: 'Confirmar'
    },
    color: {
      type: String,
      default: ''
    },
  },

  methods: {
    open(dataId) {
      this.modal = true;
      this.dataId = dataId;
    },
    close() {
      this.dataId = null;
      this.modal = false;
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm', this.dataId)
      this.close();
		},
  },
}
</script>