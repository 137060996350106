/* eslint-disable */
import HttpService from '../HttpService'

export default class MovementInsuranceCarrierService {
  constructor() {
    this._httpService = new HttpService('/sdi/movement-insurance-carrier');
  }

  async FindByParams(params) {
    return await this._httpService.get(`?${params}`);
  }

  async InsertProtocol(params) {
    return await this._httpService.put('', params);
  }

  async CancelBatch(id) {
    return await this._httpService.put(`/cancel/${id}`);
  }

  async SendByFile(id) {
    return await this._httpService.put(`/send-by-file/${id}`);
  }
  
  async MovementFinalization(id) {
    return await this._httpService.post(`/${id}/movement-finalization`);
  }
}
