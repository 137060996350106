<template>
  <v-container fluid>
    <v-tabs v-model="tab" class="mb-4" color="primary">
      <v-tab key="searchFilters" href="#searchFilters" style="font-size: 16px" aria-label="Nova Pesquisa">
        <v-icon dense class="mr-2">fas fa-search</v-icon>
        Nova Pesquisa
      </v-tab>
      <v-tab key="favoriteFilters" href="#favoriteFilters" style="font-size: 16px" aria-label="Pesquisas Salvas">
        <v-icon dense class="mr-2">far fa-bookmark</v-icon>
        Pesquisas Salvas
      </v-tab>
    </v-tabs>

    <v-tabs-items style="border-radius: 5px;" v-model="tab" grow>
      <v-tab-item key='searchFilters' value='searchFilters'>
        <v-row v-show="!overlay" class="mt-2 text--primary">
          <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
            <label>Somente protocolos manuais</label>
            <v-autocomplete
              class="mt-1"
              placeholder="Selecione"
              outlined
              dense
              color="textPrimary"
              item-color="textPrimary"
              :items="onlyManualProtocols"
              v-model="filterShippingBatches.onlyManualProtocol"
              @input="onlyManualProtocolControl"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
            <label>ID</label>
            <v-text-field
              class="mt-1"
              v-model="filterShippingBatches.id"
              v-mask="'##########'"
              type="number"
              min="1"
              @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
              @blur="validateFieldNumber('id')"
              color="textPrimary"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
            <label>Grupo Financeiro</label>
            <v-autocomplete
              class="mt-1"
              placeholder="Selecione"
              :items="financialGroups"
              v-model="filterShippingBatches.financialGroupIds"
              :rules="filterShippingBatches.financialGroupIds && filterShippingBatches.financialGroupIds.length > 0 ? [rule.handleSelectionLimit(filterShippingBatches.financialGroupIds, 5)] : []"
              item-text="name"
              item-value="id"
              outlined
              dense
              color="textPrimary"
              item-color="textPrimary"
              deletable-chips
              chips
              small-chips
              clearable
              multiple
            >
            <template v-slot:selection="data">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip class="mb-2" v-on="on" v-bind="attrs"> {{ data.item.name }} </v-chip>
                </template>
                <span>{{ data.item.name }}</span>
              </v-tooltip>
            </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
            <label>Operadora</label>
            <v-autocomplete
              class="mt-1"
              v-model="filterShippingBatches.insuranceCarrierId"
              :items="insuranceCarriers"
              item-text="xipp_commercial_name"
              item-value="id"
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
            <label>Nº do protocolo</label>
            <v-text-field
              class="mt-1"
              v-model="filterShippingBatches.protocolNumber"
              v-mask="'##########'"
              type="number"
              min="1"
              @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
              @blur="validateFieldNumber('protocolNumber')"
              color="textPrimary"
              dense
              outlined
              :disabled="filterShippingBatches.onlyManualProtocol ? true : false"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
            <label>Status</label>
            <v-autocomplete
              class="mt-1"
              :items="batchStatus"
              v-model="filterShippingBatches.status"
              placeholder="Selecione"
              item-text="text"
              item-value="value"
              outlined
              dense
              hide-details
              color="textPrimary"
              item-color="textPrimary"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
            <label>Criado por</label>
            <v-text-field
              class="mt-1"
              v-model.trim="filterShippingBatches.createdBy"
              @blur="filterShippingBatches.createdBy = formatter.formatToTitleCase(filterShippingBatches.createdBy)"
              color="textPrimary"
              dense
              outlined
              :rules="filterShippingBatches.createdBy ? [rule.name, rule.validateIsAlphanumeric] : []"
              validate-on-blur
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
            <label>Tipo do Lote</label>
            <v-autocomplete
              class="mt-1"
              :items="batchTypes"
              v-model="filterShippingBatches.batchType"
              placeholder="Selecione"
              item-text="text"
              item-value="value"
              outlined
              dense
              hide-details
              color="textPrimary"
              item-color="textPrimary"
            />
          </v-col>          
        </v-row>

        <v-row v-show="overlay" class="primary--text my-15">
          <v-col cols="12" align="center">
            <v-progress-circular
              color="primary"
              indeterminate
              size="64"
              width="8"
            />
          </v-col>
          <v-col cols=12 align="center">
            Carregando Filtros
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item key='favoriteFilters' value='favoriteFilters'>
        <FavoriteSearchComponent
          filterBy="ShippingBatcheContentFilter"
          :searchFilter="filterShippingBatches"
          :changeTabValue="changeTabValue"
          @loadThisFilter="loadThisFilter"
        />
      </v-tab-item>
    </v-tabs-items>

  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import MovementRecordsMixin from '@/shared/mixins/movementRecords/movementRecordsMixin';
import FavoriteSearchComponent from '@/components/FavoriteSearchFilters/FavoriteSearchComponent.vue';

export default ({
  name: 'ShippingBatcheContentFilter',

  components: { FavoriteSearchComponent },

  data: () => ({
    tab: 'searchFilters',
    changeTabValue: 'BATCH',
    overlay: false,

    onlyManualProtocols: [
      { text: 'Sim', value: true },
      { text: 'Não', value: false },
    ],
    batchStatus: [
      {
        text: 'Em Processamento',
        value: ['WAIT_TRANSFORMATION', 'TRANSFORMATION_IN_PROGRESS', 'WAIT_CONVERSION', 'CONVERSION_IN_PROGRESS', 'WAIT_SEND_API'],
      },
      {
        text: 'Processando retorno',
        value: 'PROCESSING_RETURN',
      },
      {
        text: 'Retorno processado',
        value: 'CARRIER_RETURN_PROCESSED',
      },
      {
        text: 'Aguardando Envio',
        value: 'FILE_GENERATED',
      },
      {
        text: 'Enviado',
        value: 'SUBMITTED',
      },
      {
        text: 'Criticado na API',
        value: 'API_INVALID_IN_CARRIER',
      },
      {
        text: 'Enviado Parcialmente',
        value: 'API_PARTIAL_SUBMITTED',
      },
      {
        text: 'Cancelado',
        value: 'CANCELED',
      },
      {
        text: 'Erro na API',
        value: 'API_ERROR',
      },
      {
        text: 'Finalizando Lote',
        value: 'WAIT_SEND_FILE',
      },
    ],
    batchTypes: [
      {
        value: '',
        text: 'Ambos',
      },
      {
        value: 'FILE',
        text: 'Arquivo',
      },
      {
        value: 'API',
        text: 'API',
      },
    ],
  }),

  mixins: [
    MovementRecordsMixin,
  ],

  props: {
    insuranceCarriers: {
      type: Array,
      default: () => [],
    },
    dates: { type: Object },
    resetFilterShippingBatches: { type: Boolean },
    financialGroups: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    filterShippingBatches: {
      deep: true,
      handler(value) {
        if (value) {
          this.$emit('hasFiltersShippingBatches', this.filterShippingBatches);
        }
      },
    },
    resetFilterShippingBatches: {
      deep: true,
      handler(value) {
        if (value) {
          this.clearFilterShippingBatches();
        }
      },
    },
  },

  created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
  },

  methods: {
    onlyManualProtocolControl() {
      if (this.filterShippingBatches.onlyManualProtocol) this.filterShippingBatches.protocolNumber = '';
    },
    validateFieldNumber(fieldName) {
      const fieldValue = this.filterShippingBatches[fieldName];
      const sanitizedValue = fieldValue.replace(/[^0-9]/g, '');
      if (sanitizedValue === '' || sanitizedValue === 0 || typeof sanitizedValue === 'undefined') {
        this.filterShippingBatches[fieldName] = '';
      } else {
        this.filterShippingBatches[fieldName] = sanitizedValue.replace(/^0+/, '');
      }
    },
    clearFilterShippingBatches() {
      this.filterShippingBatches = {
        id: '',
        insuranceCarrierId: [],
        createdAtStart: '',
        createdAtEnd: '',
        sendAtStart: '',
        sendAtEnd: '',
        protocolNumber: '',
        status: '',
        createdBy: '',
        batchType: '',
        movementInsuranceCarrierId: '',
        onlyManualProtocol: false,
        financialGroupIds: [],
      };
      this.$forceUpdate();
      this.$emit('resetStatusFilters', false);
    },
    setOverlay(value) {
      this.overlay = value;
    },
    loadThisFilter(data) {
      this.setOverlay(true);
      if (data.queryParams.status && data.queryParams.status.includes(',')) {
        data.queryParams.status = data.queryParams.status.split(',');
      }

      const dateType = this.getDateTypes(data.queryParams);

      this.filterShippingBatches = {
        id: data.queryParams.id ? data.queryParams.id : '',
        createdAtStart: data.queryParams.createdAtStart ? data.queryParams.createdAtStart : '',
        createdAtEnd: data.queryParams.createdAtEnd ? data.queryParams.createdAtEnd : '',
        sendAtStart: data.queryParams.sendAtStart ? data.queryParams.sendAtStart : '',
        sendAtEnd: data.queryParams.sendAtEnd ? data.queryParams.sendAtEnd : '',
        protocolNumber: data.queryParams.protocolNumber ? data.queryParams.protocolNumber : '',
        status: data.queryParams.status ? data.queryParams.status : '',
        createdBy: data.queryParams.createdBy ? data.queryParams.createdBy : '',
        batchType: data.queryParams.batchType ? data.queryParams.batchType : '',
        movementInsuranceCarrierId: data.queryParams.movementInsuranceCarrierId ? data.queryParams.movementInsuranceCarrierId : '',
        onlyManualProtocol: data.queryParams.onlyManualProtocol === 'true',
        insuranceCarrierId: data.queryParams.insuranceCarrierId ? Number(data.queryParams.insuranceCarrierId) : null,
        selectedDate: data.queryParams.selectedDate,
        dateType,
        financialGroupIds: data.queryParams.financialGroupIds ? data.queryParams.financialGroupIds : [],
      };

      this.tab = 'searchFilters';
      this.$emit('hasFiltersShippingBatches', this.filterShippingBatches, 'getFilterByFavorite');

      setTimeout(() => {
        this.setOverlay(false);
      }, 500);
    },
    getDateTypes(queryParams) {
      let dateType = [];

      if (queryParams.createdAtStart && queryParams.createdAtStart !== '') {
        dateType.push('createdAt');
      }

      if (queryParams.sendAtStart && queryParams.sendAtStart !== '') {
        dateType.push('sendAt');
      }

      if (queryParams.createdAtStart && queryParams.createdAtStart !== '' && queryParams.sendAtStart && queryParams.sendAtStart !== '') {
        dateType = ['createdAt', 'sendAt'];
      }

      if (dateType.length === 0) {
        dateType.push('createdAt');
      }

      return dateType;
    },
  },
});
</script>
