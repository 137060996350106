/* eslint-disable */
import HttpService from '../HttpService'

export default class InsuranceCarrierPeriodicityService {
  constructor() {
    this._httpService = new HttpService('/sdi/insurance-carrier-periodicity');
  }

  async Save(data) {
    return await this._httpService.post('', data);
  }

  async FindAll() {
    return await this._httpService.get('');
  }
  
  async FindById(id) {
    return await this._httpService.get(`/${id}`);
  }

  async FindByInsuranceCarrierId(id) {
    return await this._httpService.get(`/findByInsuranceCarrierId/${id}`);
  }

  async UploadPeriodicity(id, data) {
    return await this._httpService.put(`/${id}`, data);
  }

  async DeletePeriodicity(id) {
    return await this._httpService.delete(`/${id}`);
  }

}