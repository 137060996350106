<template>
  <div>
    <div
      class="d-flex justify-center"
      style="height: 120px;">
      <v-icon
        color="error"
        size="60">
        fas fa-ban
      </v-icon>
    </div>
    <v-sheet
      color="#F4F6F7"
      class="d-flex flex-column justify-center align-center font-weight-bold rounded-lg"
      style="width: 100%; height: 150px;">
      <p
        class="mb-0 pb-0 error--text">
        O arquivo nº {{ fileMovementToCancel.id }} não pode ser cancelado. :(
      </p>
      <p
        class="font-weight-regular pb-0 mb-0">
        O processamento do arquivo já foi concluído, mas você ainda pode cancelar as movimentações geradas.
      </p>
    </v-sheet>
  </div>
</template>
<script>
export default {
  name: "ProcessFileInfo",

  props: {
    fileMovementToCancel: null
  }
}
</script>