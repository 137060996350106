/* eslint-disable */
import HttpService from '../HttpService'

export default class ManagementImplantationService {
  constructor() {
    this._httpService = new HttpService('/sdi/management/implantations');
  }

  async FindManagementImplantationsByFilter(params) {
    return await this._httpService.get(`?${params}`);
  }

  async ManagementImplantationsSync(entityType, data) {
    return await this._httpService.post(`/entity-type/${entityType}`, data);
  }
}
