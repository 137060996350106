import HttpService from '../HttpService'
import axios from 'axios';

export default class MyReportsService {
  constructor() {
    this._httpService = new HttpService('/sdi/report/movement-record/filter');
    this._httpServiceError = new HttpService('/sdi/report/movement-record/erros');
  }

  // fetch user reports
  async GetReports(params) {
    return await this._httpService.get('', params);
  };

  async GetReportError(reportId) {
    return await this._httpServiceError.get(`/${reportId}`);
  }
}
