<template>
  <v-card-text>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-form ref="formRegisterNewNote" @submit.prevent="registerNewNote">
          <v-text-field
            v-model="newNote"
            append-icon="mdi-send"
            placeholder="Registrar um comentário"
            outlined
            @click:append="registerNewNote"
            :rules="newNote ? [] : ['Para registrar uma nota, este campo é obrigatório']"
          />
        </v-form>
      </v-col>

      <v-col cols="12 pl-10 pr-6">
        <div class="loading-overlay" v-show="loadingNote">
          <v-progress-circular color="primary" indeterminate size="100"/>
        </div>

        <v-row :style="listContainerStyle">
          <v-col cols="12">
            <div v-for="(note, index) in internalNotesData" :key="note.id">
              <v-row>
                <v-col cols="11 pa-0">
                  <span v-if="!note.editing" :class="{ 'text-decoration-line-through': note.deleted }" class="text-subtitle-1">
                    {{ note.description }}
                  </span>
                  <v-form v-else v-model="formEditNote" @submit.prevent="handleSendRequest(note, index)">
                    <v-text-field
                      :id="'noteTextField_' + index"
                      v-model="note.description"
                      dense
                      solo
                      :rules="note.description ? [] : ['Campo Obrigatório']"
                    />
                  </v-form>

                  <p :class="{ 'text-decoration-line-through': note.deleted }" class="grey--text font-weight-regular">
                    {{ note.subDescription }}
                  </p>
                </v-col>

                <v-col cols="1">
                  <v-row justify="end">
                    <v-col class="pa-0" cols="auto">
                      <v-btn color="primary" icon :disabled="note.deleted" style="pointer-events: auto">
                        <v-icon v-if="!note.editing" :disabled="note.deleted" @click="toggleEditing(note, index)">mdi mdi-pencil-outline</v-icon>
                        <v-icon v-else @click="handleSendRequest(note, index);">mdi-send</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col class="pa-0" cols="auto">
                      <v-btn color="red" icon :disabled="note.deleted" @click="deleteInternalNote(note)" style="pointer-events: auto">
                        <v-icon>mdi mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-row class="mb-5 mt-10 mx-3" justify="space-between">
        <div>
          <v-btn color="primary" large outlined @click="close()">
            <v-icon class="mr-2">mdi mdi-close</v-icon>
            Fechar
          </v-btn>
        </div>
      </v-row>
    </v-row>
  </v-card-text>
</template>

<script>
import sdiServiceManager from '@/services-http/sdi/SDIServiceManager';
import Formatters from '@/shared/formatters/formatters';
import { cloneDeep } from 'lodash';

export default ({
  name: 'InternalNotesTabContentComponent',

  data: () => ({
    newNote: '', internalNotesData: [], loadingNote: false, noteDefault: {}, formEditNote: false,
  }),

  props: { movementRecordId: Number },

  computed: {
    listContainerStyle() {
      return {
        overflowY: this.internalNotesData && this.internalNotesData.length > 4 ? 'auto' : 'visible',
        maxHeight: this.internalNotesData && this.internalNotesData.length > 4 ? '220px' : 'auto',
      };
    },
  },

  created() {
    this.formatter = new Formatters();
    this.serviceController();
  },

  methods: {
    serviceController() {
      this.internalNoteService = sdiServiceManager.getInternalNoteService();
    },
    loadInternalNotes() {
      this.internalNotesData = [];
      this.$refs.formRegisterNewNote.reset();
      this.setLoading(true);
      this.internalNoteService.FindByMovementRecordId(this.movementRecordId).then((response) => {
        if (response && response.data && response.data.length > 0) {
          this.internalNotesData = response.data;
          this.formatNotes();
        }
        this.setLoading(false);
      }).catch(() => {
        this.setSnackbarCustomize('error', 'Ocorreu um erro ao buscar as notas do usuário');
        this.setLoading(false);
      });
    },
    formatNotes() {
      if (this.internalNotesData && this.internalNotesData.length > 0) {
        this.internalNotesData = this.internalNotesData.map((note) => {
          const formattedDate = note.updatedAt ? this.formatter.formatDateTimeZoneWithHours(note.updatedAt) : this.formatter.formatDateTimeZoneWithHours(note.createdAt);
          const subDescription = note.updatedAt !== null && !note.deleted ? `${formattedDate} - ${note.userName} (editada)` : `${formattedDate} - ${note.userName}`;

          return {
            id: note.id,
            movementRecordId: note.movementRecordId,
            userName: note.userName,
            userId: note.userId,
            description: note.note,
            subDescription,
            createdAt: note.createdAt,
            updatedAt: note.updatedAt,
            deleted: note.deleted,
            edited: note.updatedAt !== null,
            editing: false,
          };
        });
      }
    },
    registerNewNote() {
      if (this.$refs.formRegisterNewNote.validate()) {
        const data = {
          note: this.newNote,
          movementRecordId: this.movementRecordId,
        };

        this.internalNoteService.Save(data).then(() => {
          this.loadInternalNotes();
        }).catch(() => {
          this.setSnackbarCustomize('error', 'Ocorreu um erro ao criar uma nota');
        });
      }
    },
    sendRequestOnEditing(note, index) {
      this.setLoading(true);
      if (this.formEditNote) {
        this.internalNotesData[index].editing = false;

        const data = {
          id: note.id,
          note: note.description,
          movementRecordId: this.movementRecordId,
        };

        this.internalNoteService.Update(note.id, data).then(() => {
          this.loadInternalNotes();
        }).catch(() => {
          this.setSnackbarCustomize('error', 'Ocorreu um erro ao editar a nota');
          this.setLoading(false);
        });
      }
    },
    toggleEditing(note, index) {
      this.$refs.formRegisterNewNote.reset();
      const itemInEditing = this.internalNotesData.find((item) => item.editing);

      if (itemInEditing && itemInEditing !== this.internalNotesData[index]) {
        itemInEditing.editing = false;
        itemInEditing.description = cloneDeep(this.noteDefault.description);
      }

      this.noteDefault = cloneDeep(note);
      this.internalNotesData[index].editing = true;

      setTimeout(() => {
        const element = document.getElementById(`noteTextField_${index}`);
        element.focus();
      }, 100);
    },
    handleSendRequest(note, index) {
      if (this.internalNotesData[index].editing) {
        this.sendRequestOnEditing(note, index);
      }
    },
    deleteInternalNote(note) {
      this.setLoading(true);

      this.internalNoteService.Delete(note.id).then(() => {
        this.loadInternalNotes();
      }).catch(() => {
        this.setSnackbarCustomize('error', 'Ocorreu um erro ao deletar a nota');
        this.setLoading(false);
      });
    },
    setLoading(value) {
      this.loadingNote = value;
    },
    setSnackbarCustomize(type, message) {
      this.$emit('setSnackbarCustomize', type, message);
    },
    close() {
      this.internalNotesData = [];
      this.$emit('close');
    },
  },
});
</script>

<style scoped>
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-overlay v-progress-circular {
  margin-right: 8px;
}
</style>
