var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"790"},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-card',{staticClass:"rounded-lg d-flex flex-column",staticStyle:{"min-height":"500px"}},[_c('v-card-title',{staticClass:"pt-9 px-12"},[_c('v-row',{staticClass:"text-center d-flex justify-start"},[_c('span',{staticClass:"text-h6 font-weight-bold primary--text"},[_vm._v(" Cancelar Arquivo de Movimentação ")]),_c('v-spacer'),_c('v-icon',{attrs:{"size":"25","color":"grey"},on:{"click":_vm.close}},[_vm._v(" close ")])],1)],1),_c('v-card-text',{staticClass:"pa-9 d-flex flex-column justify-space-around flex-grow-1 flex-wrap"},[(!_vm.showErrorMessage)?_c('div',[(_vm.cancelationSuccess)?_c('CancelationSucessInfo',{attrs:{"fileMovementToCancel":_vm.fileMovementToCancel}}):_vm._e(),(_vm.fileMovementToCancel.protocolNumber != null
                  && ['CONVERTED', 'COLLECTED'].some(status => status === _vm.fileMovementToCancel.status)
                  && _vm.fileMovementToCancel.processedElements === 0
                  && !_vm.cancelationSuccess)?_c('ConfirmCancelationInfo',{attrs:{"fileMovementToCancel":_vm.fileMovementToCancel}}):_vm._e(),(['INVALID', 'REFUSED'].some(status => status === _vm.fileMovementToCancel.status)
                  && !_vm.cancelationSuccess)?_c('ProcessFileWithFailsInfo',{attrs:{"fileMovementToCancel":_vm.fileMovementToCancel}}):_vm._e(),(['PARTIAL', 'FINISHED'].some(status => status  === _vm.fileMovementToCancel.status)
                  && !_vm.cancelationSuccess)?_c('ProcessFileInfo',{attrs:{"fileMovementToCancel":_vm.fileMovementToCancel}}):_vm._e()],1):_c('div',[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"120px"}},[_c('v-icon',{attrs:{"color":"error","size":"60"}},[_vm._v(" fas fa-ban ")])],1),_c('v-sheet',{staticClass:"d-flex flex-column justify-center align-center font-weight-bold rounded-lg",staticStyle:{"width":"100%","height":"150px"},attrs:{"color":"#F4F6F7"}},[_c('p',{staticClass:"mb-0 pb-0 error--text"},[_vm._v(" Os dados do arquivo nº "+_vm._s(_vm.fileMovementToCancel.id)+" não pode ser cancelado. :( ")])])],1)]),_c('v-card-actions',{staticClass:"px-12 pb-9"},[(!_vm.showErrorMessage)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),(!['INVALID', 'REFUSED'].some(status => status === _vm.fileMovementToCancel.status)
                  && !_vm.cancelationSuccess)?_c('v-btn',{staticClass:"text-body-2 elevation-0 primary--text mr-3",attrs:{"height":"40","color":"#EDEFF1"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-arrow-left ")]),_vm._v(" Voltar ")],1):_vm._e(),(_vm.fileMovementToCancel.protocolNumber != null
                  && ['CONVERTED', 'COLLECTED'].some(status => status === _vm.fileMovementToCancel.status)
                  && _vm.fileMovementToCancel.processedElements === 0
                  && !_vm.cancelationSuccess)?_c('v-btn',{staticClass:"text-body-2 elevation-0 primary",attrs:{"height":"40","loading":_vm.loading},on:{"click":_vm.requestCancellation}},[_vm._v(" Solicitar o cancelamento "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" fas fa-arrow-right ")])],1):_vm._e(),(['PARTIAL', 'FINISHED'].some(status => status  === _vm.fileMovementToCancel.status)
                  && !_vm.cancelationSuccess)?_c('v-btn',{staticClass:"text-body-2 elevation-0 primary",attrs:{"height":"40","loading":_vm.loading},on:{"click":_vm.cancelMovements}},[_vm._v(" Cancelar movimentações "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" fas fa-arrow-right ")])],1):_vm._e(),(['INVALID', 'REFUSED'].some(status => status === _vm.fileMovementToCancel.status) || _vm.cancelationSuccess)?_c('v-btn',{staticClass:"text-body-2 elevation-0 primary",attrs:{"height":"40","loading":_vm.loading},on:{"click":_vm.close}},[_vm._v(" Concluir "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" fas fa-arrow-right ")])],1):_vm._e()],1):_c('v-row',[_c('v-spacer'),_c('v-btn',{staticClass:"text-body-2 elevation-0 primary",attrs:{"height":"40"},on:{"click":_vm.close}},[_vm._v(" Concluir "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" fas fa-arrow-right ")])],1)],1)],1)],1),_c('SnackbarCustomize',{ref:"SnackbarCustomize"})]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}})
}
var staticRenderFns = []

export { render, staticRenderFns }