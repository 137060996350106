/* eslint-disable */
import HttpService from '../HttpService'

export default class DocumentTypeService {
  constructor() {
    this._httpService = new HttpService('/sdi/document-type');
  }

  async FindAllDocumentType(filter) {
    var queryString = new URLSearchParams(filter);
    return await this._httpService.get(`?${queryString}`);
  }
};
