<template>
  <v-dialog
    max-width="790"
    v-model="modal">
    <template v-slot:default="dialog">
      <v-card
        class="rounded-lg d-flex flex-column"
        style="min-height: 720px;">
        <v-card-title
          class="pt-9 px-12">
          <v-row
            class="text-center d-flex justify-start">
            <span
              class="text-h6 font-weight-bold primary--text">
              Cancelamento de movimentação
            </span>
            <v-spacer />
            <v-icon
              size="25"
              color="grey"
              @click="close">
              close
            </v-icon>
          </v-row>
        </v-card-title>
        <v-card-text
          class="pa-9 d-flex flex-column flex-grow-1">
          <div
            class="d-flex flex-column align-center justify-center flex-grow-1"
            v-if="loading">
            <v-progress-circular
              :rotate="360"
              :size="150"
              :width="12"
              :value="progress"
              class="mb-4"
              color="#1C9CA7">
              <span
                class="text-h5">
                {{progress}}%
              </span>
            </v-progress-circular>
            <div
              v-if="progress < 100"
              class="text-center">
              <p
                class="text-h6 font-weight-bold pb-0 mb-0">
                Aguarde um instante enquanto fazemos as operações solicitadas...
              </p>
              <p
              class="text-h6">
                Por favor, mantenha a janela do seu navegador aberta.
              </p>
            </div>
            <p
              v-else
              class="text-h6">
              Operação concluída!
            </p>
          </div>
          <v-row
            no-gutters
            v-if="!cancellationCompleted">
            <v-form
              ref="CancelMovementsForm">
              <v-col cols="12">
                <label>
                  Selecione o motivo do Cancelamento
                  <span
                    class="red--text">
                    *
                  </span>
                </label>
                <v-autocomplete
                  v-model="reasonCancellationCode"
                  class="rounded-lg"
                  item-text="name"
                  return-object
                  filled
                  rounded
                  color="textPrimary"
                  item-color="textPrimary"
                  :items="cancellationReasons"
                  :rules="[rule.required]"
                  @change="setCancelationReasonType"
                />
              </v-col>
              <v-col cols="12" v-if="!Boolean(isRHProtegido)">
                <label>
                  Adicione uma nota interna (opcional)
                </label>
                <v-textarea
                  v-model="internalNote"
                  class="rounded-lg"
                  placeholder="Digite aqui..."
                  filled
                  rounded
                />
              </v-col>
              <v-col cols="12">
                <div
                  class="d-flex flex-column justify-space-between"
                  style="height: 130px;">
                  <AlertCard
                    v-if="Boolean(cancelationReasonType) && cancelationReasonType === 'internal'"
                    :textColor="'#0089DF'"
                    :bodyColor="'#E3EEF6'"
                    :title="'Você selecionou um motivo de cancelamento interno.'"
                    :description="'\
                      Os registros podem ficar ocultos para\
                      usuários externos a menos que tenha sido\
                      criada pelo próprio cliente.'"
                  />
                  <AlertCard
                    :icon="'fas fa-exclamation-triangle'"
                    :textColor="'#D78F08'"
                    :bodyColor="'#FDF5E6'"
                    :title="'Esta ação não poderá ser desfeita'"
                    :description="'\
                      Ao confirmar o cancelamento, os registros serão invalidados\
                      e não poderão mais ser editados.'"
                  />
                </div>
              </v-col>
            </v-form>
          </v-row>
          <v-expand-transition>
            <v-col
              no-gutters
              v-if="cancellationCompleted && progress === 100">
              <v-expansion-panels
                accordion
                class="elevation-0">
                <v-expansion-panel
                  class="elevation-0">
                  <v-expansion-panel-header
                    class="success--text">
                    {{ result ? result.canceled_ids.length : '' }}
                    {{result
                      ? result.canceled_ids.length.length > 1
                        ? 'movimentações canceladas com sucesso. ;)'
                        : 'movimentação cancelada com sucesso. ;)'
                      : ''
                    }}
                    <template v-slot:actions>
                      <v-icon
                        color="success"
                        size="15">
                        fas fa-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p
                      class="pb-0 mb-0">
                      As movimentações não poderão mais ser editadas ou enviadas para a operadora.
                    </p>
                    <p
                      class="pb-0 mb-0 grey--text">
                      Nenhuma ação adicional é necessária.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel
                  class="elevation-0">
                  <v-expansion-panel-header
                    class="error--text"
                    v-if="Boolean(result)">
                    {{ result.not_canceled_ids.length }}
                    {{
                      result.not_canceled_ids.length > 1
                      ? 'movimentações não foram canceladas.'
                      : 'movimentação não foi cancelada.'
                    }} :(
                    <template v-slot:actions>
                      <v-icon
                        color="error"
                        size="15">
                        fas fa-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      Alguns motivos possiveis para isso ter ocorrido:
                    </p>
                    <p
                      class="pb-0 mb-0 grey--text">
                      1. A movimentação selecionada estava em um status que não permite o cancelamento.
                    </p>
                    <p
                      class="pb-0 mb-0 grey--text">
                      2. A movimentação pode estar vinculada à outro registro (titular/dependente) que não permite o cancelamento.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-expand-transition>
        </v-card-text>
        <v-card-actions
          class="px-12 pb-9">
          <v-row
            no-gutters
            v-if="!cancellationCompleted">
            <v-spacer />
            <v-btn
              class="text-body-2 elevation-0 primary--text mr-3"
              height="40"
              color="#EDEFF1"
              @click="close">
              <v-icon
                left>
                fas fa-arrow-left
              </v-icon>
              Voltar
            </v-btn>
            <v-btn
              height="40"
              class="text-body-2 elevation-0 primary"
              @click="cancelMovements">
              Confirmar o cancelamento de
              {{ movementSelecteds.length }}
              {{ movementSelecteds.length > 1 ? 'movimentações' : 'movimentação' }}
              <v-icon
                right>
                fas fa-arrow-right
              </v-icon>
            </v-btn>
          </v-row>
          <v-row
            no-gutters
            v-if="cancellationCompleted && progress === 100">
            <v-spacer />
            <v-btn
              class="text-body-2 elevation-0 primary--text mr-3"
              height="40"
              color="#EDEFF1"
              @click="close">
              <v-icon
                left>
                fas fa-arrow-left
              </v-icon>
              Fechar
            </v-btn>
            <v-btn
              height="40"
              class="text-body-2 elevation-0 primary"
              @click="showResult">
              Ver o resultado
              <v-icon
                right>
                fas fa-arrow-right
              </v-icon>
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
      <SnackbarCustomize ref="SnackbarCustomize" />
    </template>
  </v-dialog>
</template>
<script>
import moment from 'moment';
import Rules from '@/shared/validators/formRules';
import AlertCard from "@/components/CustomAlerts/AlertCard.vue"
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
export default {
  name: "CancelMovementBlockModal",

  components: {
    AlertCard,
    SnackbarCustomize,
  },

  data: () => ({
    rule: null,
    modal: false,
    result: null,
    loading: false,
    moment: moment,
    showLoader: false,
    internalNote: null,
    movementSelecteds: [],
    movementService: null,
    cancellationCompleted: false,
    reasonCancellationCode: null,
    cancelationReasonType: null,

    progress: 0,
    intervalId: null,
    intervalIndex: 0,
    intervals: [5, 20, 45, 60, 75],
  }),

  props: {
    cancellationReasons: Array,
    fileDataId: null,
    isMovementFlow: Boolean,
    isRHProtegido: Boolean,
  },

  methods: {
    open(movementSelecteds) {
      this.movementSelecteds = movementSelecteds;
      this.modal = true;
    },
    close() {
      this.modal = false;
      this.resetData();
    },
    async cancelMovements() {
      if (!this.$refs.CancelMovementsForm.validate()) return;

      this.loading = true;
      this.cancellationCompleted = true
      this.setProcessLoader();

      let data = null

      if (this.isMovementFlow) {
        data = {
          internalNote: this.internalNote,
          movementRecordIds: this.movementSelecteds,
          reasonCancellationCode: this.reasonCancellationCode,
        }
      } else {
        data = {
          internalNote: this.internalNote,
          fileDataId: this.fileDataId,
          reasonCancellationCode: this.reasonCancellationCode,
        }
      }

      await this.movementService.CancelMovements(data).then((response) => {
        this.cancellationCompleted = true
        this.result = response.data;
        this.progress = 100;
      }).catch(() => {
        this.loading = false;
      }).finally(() => {
        clearTimeout();
      });
    },
    showResult() {
      this.$emit('showResult');
      this.close();
    },
    setCancelationReasonType(event) {
      this.reasonCancellationCode = event.name;
      this.cancelationReasonType = event.cancelationReasonType;
    },
    setProcessLoader() {
      const timeout = this.setTimeoutRandon();
      setTimeout(() => this.loaderControl(), timeout);
    },
    setTimeoutRandon() {
      return Math.floor(Math.random() * (4000 - 2000 + 1)) + 2000
    },
    loaderControl() {
      const nextValue = this.intervals[this.intervalIndex];
      if (this.progress + nextValue <= 75) {
        this.progress += nextValue;
        this.setProcessLoader()
      } else {
        this.progress = 100;
      }
      this.intervalIndex = (this.intervalIndex + 1) % this.intervals.length;
    },
    resetData() {
      this.progress = 0;
      this.result = null;
      this.loading = false;
      this.intervalId = null;
      this.intervalIndex = 0;
      this.showLoader = false;
      this.internalNote = null;
      this.movementSelecteds = [];
      this.cancelationReasonType = null;
      this.cancellationCompleted = false;
      this.reasonCancellationCode = null;
    },
  },

  created() {
    this.rule = new Rules();
    this.movementService = new MovementRecordService();
  },

  beforeDestroy() {
    this.resetData();
  }
}
</script>
