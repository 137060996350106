<template>
  <div class="text-center">
    <v-dialog v-model="showDialog" width="auto" persistent>
      <v-card class="dialog-card pa-5">
        <v-card-text v-if="messageType === 'RELEASE_TYPE_ACTION'">
          <v-row class="text-center py-5">
            <v-col cols="12" class="primary--text">
              <h2><strong>Liberar as movimentações selecionadas?</strong></h2>
            </v-col>
            <v-col cols="12" class="primary--text py-0">
              {{ totalItens }} registros selecionados
            </v-col>
          </v-row>

          <v-row class="py-0 my-0">
            <v-col cols="auto" class="px-0">
              <v-icon class="mr-2" color="blue">mdi-information-slab-circle-outline</v-icon>
            </v-col>
            <v-col class="primary--text text-justify">
              Só serão liberados aos registros selecionados que estejam no status "Aguardando liberação".<br/>
              Ao confirmar, as movimentações terão o status alterado para "Liberado" e passam a ser elegíveis para fechamento de lote de envio para a operadora.
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-if="messageType === 'REVERT_TYPE_ACTION'">
          <v-row class="text-center py-5">
            <v-col cols="12" class="primary--text">
              <h2><strong>Reverter a liberação das movimentações selecionadas?</strong></h2>
            </v-col>
            <v-col cols="12" class="primary--text py-0">
              {{ totalItens }} registros selecionados
            </v-col>
          </v-row>

          <v-row class="py-0 my-0">
            <v-col cols="auto" class="px-0">
              <v-icon class="mr-2" color="blue">mdi-information-slab-circle-outline</v-icon>
            </v-col>
            <v-col class="primary--text text-justify">
              Só serão revertidas as liberações dos registros selecionados que estejam no status "Liberado" e que não estejam em processo de fechamento do lote de envio para a operadora.<br/>
              Ao confirmar, as movimentações voltarão para o status "Aguardando Liberação".
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="py-5 mb-3 mx-5">
          <v-row justify="center">
            <v-btn color="primary" large outlined :disabled="isLoading" @click="close()">
              <v-icon class="mr-2" small>fas fa-chevron-left</v-icon>
              VOLTAR
            </v-btn>
            <v-btn color="primary" class="ml-5" large :disabled="isLoading" :loading="isLoading" @click="confirm()">
              <v-icon v-if="messageType === 'RELEASE_TYPE_ACTION'" class="mr-2" small>fas fa-clipboard-list</v-icon>
              <v-icon v-if="messageType === 'REVERT_TYPE_ACTION'" class="mr-2" small>fas fa-undo</v-icon>
              {{ messageType === 'RELEASE_TYPE_ACTION' ? 'LIBERAR MOVIMENTAÇÕES' : 'REVERTER LIBERAÇÕES' }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default ({
  name: 'ConfirmMovementDialog',
  data: () => ({
    showDialog: false,
    isLoading: false,
  }),
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    messageType: {
      type: String,
      default: 'REVERT_TYPE_ACTION',
    },
    totalItens: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(newValue) {
      this.showDialog = newValue;
    },
    loading(newValue) {
      this.isLoading = newValue;
    },
  },
  methods: {
    close() {
      this.showDialog = false;
      this.$emit('close');
    },
    confirm() {
      this.isLoading = true;
      this.$emit('confirmDialog', this.messageType);
    },
  },
});
</script>

<style scoped>
.dialog-card {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45%;
}

.dialog-transition-enter-active,
.dialog-transition-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.dialog-transition-enter,
.dialog-transition-leave-to {
  opacity: 0;
}

@media screen and (max-width: 1600px) and (min-width: 1199px) {
  .dialog-card {
    width: 60%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .dialog-card {
    width: 70%;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .dialog-card {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .dialog-card {
    width: 90%;
  }
}
</style>
