import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import MovementInsuranceCarrierReturnService from '@/services-http/sdi/MovementInsuranceCarrierReturnService';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import CarrierService from '@/services-http/odoo/CarrierService';
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import moment from 'moment';

@Component
export default class CardDialogFinish extends Vue {
  @Prop()
  show!: boolean;

  @Prop()
  movementSelecteds!: object;

  @Prop()
  readonly benefits!: any[];

  beneficiaries: any[] = [];



  codeMessage = '';

  isContitionForFamilyCode = false;

  isFreeMovement = false;

  snackbar = {
    show: false,
    type: '',
    text: '',
  };

  lengthFamilyCode = '#';

  dialogHeaders = [
    {
      text: 'Beneficiário',
      value: 'name',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Titular',
      value: 'holderName',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Tipo Movimentação',
      value: 'movementType',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Tipo Beneficiário',
      value: 'beneficiaryType',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Operadora',
      value: 'insuranceCarrierName',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Apólice / Subcontrato',
      value: 'contractName',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Data de vigência solicitada',
      value: 'endOrStartDate',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Data de vigência efetivada',
      value: 'sendEndOrStartDate',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Nº Carteirinha',
      value: 'cardNumber',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Carteirinha Congênere',
      value: 'congenericCard',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Código Familiar',
      value: 'familyCode',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Sequencial',
      value: 'familyCodeSequential',
      align: 'left',
      sortable: false,
    },
  ];

  dialog = { show: false }

  movementInsuranceCarrierReturnService = new MovementInsuranceCarrierReturnService();

  movementRecordService = new MovementRecordService();

  odooCarrierService = new CarrierService();

  formatter = new Formatters();

  rules = new Rules();

  forceFinish() {
    let beneficiaryCardsToFinish: any[] = [];

    beneficiaryCardsToFinish = this.beneficiaries.map((beneficiary) => ({
      insuredName: beneficiary && beneficiary.insuredName ? beneficiary.insuredName : '',
      familyGroupId: beneficiary && beneficiary.familyGroupId ? beneficiary.familyGroupId : '',
      beneficiaryType: beneficiary && beneficiary.beneficiaryType ? beneficiary.beneficiaryType : '',
      cardNumber: beneficiary && beneficiary.cardNumber ? beneficiary.cardNumber : '',
      congenericCard: beneficiary && beneficiary.congenericCard ? beneficiary.congenericCard : null,
      movementInsuranceCarrierId: beneficiary && beneficiary.movementInsuranceCarrierId ? beneficiary.movementInsuranceCarrierId : null,
      movementRecordId: beneficiary && beneficiary.movementRecordId ? beneficiary.movementRecordId : null,
      familyCode: beneficiary && beneficiary.familyCode ? beneficiary.familyCode : null,
      familyCodeSequential: beneficiary && beneficiary.familyCodeSequential ? beneficiary.familyCodeSequential : null,
      sendStartDate: beneficiary && beneficiary.sendStartDate && !beneficiary.movementType.startsWith('DELETE') ? this.formatter.formatDateBRtoString(beneficiary.sendStartDate) : null,
      sendEndDate: beneficiary && beneficiary.sendEndDate && beneficiary.movementType.startsWith('DELETE') ? this.formatter.formatDateBRtoString(beneficiary.sendEndDate) : null,
    }));

    let dateError = false;
    let holders = beneficiaryCardsToFinish.filter((h) => h.beneficiaryType === 'HOLDER');
    holders.forEach((h) => {
      let dependents = beneficiaryCardsToFinish.filter((d) => d.beneficiaryType === 'DEPENDENT' && d.familyGroupId === h.familyGroupId);
      
      dependents.forEach((d) => {
        if (moment(d.sendStartDate).isAfter(h.sendStartDate)) {
          this.customizeSnackbarMessage('error', `A data do dependente ${d.insuredName} não pode ser maior que do titular.`);
          dateError = true;
          return;
        }
      });
      if(dateError) return;
    });

    if(dateError) return;

    if (beneficiaryCardsToFinish.some((element: any) => (Object.values(element).some((value) => value === '')))) {
      this.customizeSnackbarMessage('error', 'Por favor, preencha ao menos o campo "Nº Carteirinha" para prosseguir.');
    } else {
      this.$emit('on-confirm', true);

      this.movementRecordService.ForceFinish(beneficiaryCardsToFinish).then(() => {
        this.customizeSnackbarMessage('success', 'Movimentação(es) Finalizada(s) com sucesso.');
      }).catch(() => {
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao tentar finalizar a(s) movimentação(es).');
      });
    }
  }

  formatBenefitType(benefitType: string) {
    let benefitName = '-';
    if (this.benefits && this.benefits.length > 0) {
      const benefitFound = this.benefits.find((benefit) => benefit.xipp_contract_record_type_code === benefitType);
      benefitName = benefitFound && benefitFound.name ? benefitFound.name : '-';
    }
    return benefitName;
  }
  benefitData(item : any) {
    const { policy = '-', subContract = '-', benefitType } = item || {};
    const formattedBenefitType = benefitType ? `${this.formatBenefitType(benefitType)}` : '-';

    const policyValue = policy !== '-' ? `${policy} / ` : '- ';
    const subContractValue = subContract !== '-' ? `${subContract}` : '-';
    const formattedBenefitTypeValue = formattedBenefitType !== '-' ? `${formattedBenefitType} - ` : '';

    return `${formattedBenefitTypeValue}${policyValue}${subContractValue}`;
  }

  customizeSnackbarMessage(type: string, text: string) {
    this.snackbar.show = true;
    this.snackbar.type = type;
    this.snackbar.text = text;
  }

  created() {
    const hash = '#';
    this.lengthFamilyCode = hash.repeat(50);
    this.dialog.show = this.show;
  }

  @Watch('show', { immediate: true, deep: true })
  onDisplayModalChange(val: boolean) {
    this.dialog.show = val;
  }

  @Watch('movementSelecteds', { immediate: true, deep: true })
  async onMovementSelectedsChange(val: any[]) {
    if (val && val.length > 0) {
      this.beneficiaries = await Promise.all(val.map(async (beneficiary, index) => ({
        key: index,
        insuredName: beneficiary.insuredName,
        holderName: beneficiary.holderName,
        cardNumber: beneficiary.beneficiaryCard ? beneficiary.beneficiaryCard : null,
        movementType: beneficiary.movementType,
        contract: beneficiary.contractId,
        insuranceCarrierName: beneficiary.insuranceCarrier,
        insuranceCarrierId: beneficiary.insuranceCarrierId,
        contractName: this.benefitData(beneficiary),
        subcontract: beneficiary.subContract,
        beneficiaryType: beneficiary.beneficiaryType,
        startDate: beneficiary.startDate,
        sendStartDate: beneficiary.startDate ? this.formatter.formatDate(new Date(beneficiary.startDate)) : null,
        endDate: beneficiary.endDate,
        sendEndDate: beneficiary.endDate ? this.formatter.formatDate(new Date(beneficiary.endDate)) : null,
        movementInsuranceCarrierId: beneficiary && beneficiary.movementInsuranceCarrierId ? beneficiary.movementInsuranceCarrierId : null,
        returnType: 'SUCCESS',
        movementRecordId: beneficiary && beneficiary.id ? beneficiary.id : null,
        familyCode: beneficiary.familyCode ? beneficiary.familyCode : '',
        familyCodeSequential: beneficiary.familyCodeSequential ? beneficiary.familyCodeSequential : '',
        familyGroupId: beneficiary.familyGroupId ? beneficiary.familyGroupId : '',
        isAbleToCongenericCard: null,
        congenericCard: beneficiary && beneficiary.congenericCard ? beneficiary.congenericCard : null,
      })));

      await this.verifyCongenericCard();

      this.isFreeMovement = val.some((element: any) => element.freeMovement);
    } else {
      this.beneficiaries = [];
    }
  }

  async verifyCongenericCard() {
    this.beneficiaries.forEach(async beneficiary => {
      await this.findByCarrierId(beneficiary);
    });
  }

  async findByCarrierId(beneficiary: any) {     
    await this.odooCarrierService.FindAllByCarrierId(beneficiary.insuranceCarrierId).then((response) => {
      if (response && response.data && response.data.length) {
        beneficiary.isAbleToCongenericCard = response.data[0].xipp_use_similar_card === true;
      }
    });
  }
}
