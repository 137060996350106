/* eslint-disable */
import HttpService from '../HttpService'

export default class MovementFileService {
  constructor() {
    this._httpService = new HttpService('/sdi/movement-file');
  }

  async Upload(data) {
    return await this._httpService.post('', data);
  }
}
