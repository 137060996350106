import UserUtils from '@/shared/utils/user-utils';

export default {
  data: () => ({}),
  created() {
    this.userUtils = new UserUtils();
  },
  methods: {
    hasPermission(permission) {
      return this.userUtils.hasAuthorityToAccess({ authority: permission });
    },
    hasAllPermissions(permissions) {
      return permissions.every((permission) => this.hasPermission(permission));
    },
  },
};
