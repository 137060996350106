<template>
  <div>
    <v-card-title>
      <v-row>
        <v-col class="text-h5" cols="6" style="color: #3b495b; font-weight: bold">
          {{ headerName }}
        </v-col>
        <v-col class="d-flex justify-end align-center" cols="6">
          <v-icon small color="grey" @click="close()">mdi mdi-close</v-icon>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-subtitle v-if="viewType === 'confirmCorrectionMovement'" class="mt-1">
      Ao confirmar a movimentação de correção, todas as críticas assosciadas terão o status alterado para "Resolvido". <br>
      Ao completar esta ação, entendemos que foram realizados os devidos ajustes. Você ainda poderá editar esta movimentação antes de liberar para envio à operadora.
    </v-card-subtitle>
  </div>
</template>

<script>
export default ({
  name: 'HeaderComponent',

  props: { criticismType: { type: String }, headerName: { type: String }, viewType: { type: String } },

  methods: {
    close() {
      this.$emit('close');
    },
  },
});
</script>
