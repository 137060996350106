/* eslint-disable */
import HttpService from '../HttpService';

export default class FavoriteFilterService {
  constructor(httpService) {
    this._httpService = new HttpService('/sdi/favorite-filter');
  }

  async Save(data) {
    return await this._httpService.post('', data);
  }

  async FindByContext(context) {
    return await this._httpService.get(`/?context=${context}`);
  }

  async Delete(id) {
    return await this._httpService.delete(`/${id}`);
  }
}
